import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import axios from "axios";
import {useLocalStorage} from "usehooks-ts";
import ManageUserTable from "../Tables/ManagedTables/ManageUserTable";

export default function ManageUsers() {
    const [isUser] = useLocalStorage('isUser', '')
    const [isToken] = useLocalStorage('isToken', '')
    const [isUsers, setUsers] = useState([])
    const [isGroups, setGroups] = useState([]);
    const [isChanged, setChanged] = useState(false);
    const [isLoadingUsers, setLoadingUsers] = useState(true)
    const [isLoadingGroups, setLoadingGroups] = useState(true)

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`}};
    // --------------------------------- //

    useEffect(() => {
        const fetchData = async () => {
            if (isLoadingUsers || isChanged) {
                try {
                    const responseUsers = await axios.get("/mUsers.php", config);

                    setUsers(responseUsers.data);
                    setChanged(false);
                    setLoadingUsers(false)
                } catch (error) {
                    console.error("Fehler beim Laden der Benutzer: ", error);
                    setChanged(false);
                    setLoadingUsers(false)
                }
            }
        };
        fetchData();
    }, [isLoadingUsers, isChanged]);

    useEffect(() => {
        const fetchData = async () => {
            if (isLoadingGroups || isChanged) {
                try {
                    const responseGroups = await axios.get("/mGroups.php", config);
                    const groupsData = typeof responseGroups.data === 'object' ? responseGroups.data : JSON.parse(responseGroups.data);

                    setGroups(groupsData);
                    setChanged(false);
                    setLoadingGroups(false)
                } catch (error) {
                    console.error("Fehler beim Laden der Gruppe: ", error);
                    setChanged(false);
                    setLoadingGroups(false)
                }
            }
        };

        fetchData();
    }, [isLoadingGroups, isChanged]);

    return (
        <>
            <div className="contentWrapper">

                <div className="normalFormWide">
                    <div className="loginFormContent">
                        <h1 className="loginTitle">Benutzer verwalten</h1>

                        <div className="formContainer">
                            {isUsers.length > 0 ? (
                                <ManageUserTable data={isUsers} type={"ManageUserTable"} secondData={isGroups} />
                            ) : (
                                <div className="messageWrapper">
                                    <div className="infoMessage">
                                        Es wurden keine Benutzer gefunden
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>

            </div>
        </>
    )

}
