import React, {useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import '../Login/Login.css';

export default function ActivateAccount() {
    const navigate = useNavigate();
    const [isResponse, setResponse] = useState({})
    const { token } = useParams();

    const [inputs, setInputs] = useState(
        {
            'urlToken': token
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ urlToken: inputs.urlToken })
        };

        // fetch('http://localhost:80/react/api/activateAccount.php', requestOptions)
        fetch('https://api.seelenwesen.de/activateAccount.php', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setResponse(data)
                if(data.status === 1 || data.message === "Account wurde bereits freigeschaltet!") {
                    setTimeout(function(){
                        navigate("/login")
                    }, 1500)
                }

            }).catch((err) => {
            console.log(err);
        })

    }


    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div
                        className="responseMessage_success"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }

            if (isResponse.message.length < 1) {
                return <div></div>
            } else {
                return (
                    <div
                        className="responseMessage_error"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }
        }
    }


    return (
        <>
            <div className="contentWrapper">
                <form className="loginForm" onSubmit={handleSubmit}>

                    <div className="loginFormContent">
                        <h1 className="loginTitle">Account aktivieren</h1>
                        {formatResponseMessage()}

                        <div className="inputWrapper">
                            <div style={{textAlign: 'center', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                Klicke auf den nachfolgenden Button um deinen Account zu aktivieren.
                            </div>
                        </div>

                        <div className="formButtonWrapper">
                            <div className="submitButtonWrapper">
                                <button className="submitButton" type="submit">
                                    Klicke zum Aktivieren
                                </button>
                            </div>

                            <div className="formButtonRegisterWrapper">
                                <Link className="registerLink" to={'../login'}>Zurück zum Login</Link>
                            </div>
                        </div>
                    </div>

                </form>
            </div>

        </>
    )
}