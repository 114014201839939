import React, { useRef, useState, useEffect } from "react";
import { useModal } from "./ModalProvider";
import CustomSelect from "../Custom/CustomSelect";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "usehooks-ts";
import formatName from "../Utilities/NameFormatter";

const EditUserModal = ({ initialData, secondData }) => {
    const [isUser] = useLocalStorage("isUser", "");
    const [isToken] = useLocalStorage("isToken", "");
    const [editedData, setEditedData] = useState(initialData);
    const [deleteButtonText, setDeleteButtonText] = useState("Löschen");
    const deleteButtonTimerRef = useRef(null);
    const [isResponse, setResponse] = useState({});
    const [responseTimer, setResponseTimer] = useState(null);

    const { closeModal } = useModal();

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //

    /* the key "name" is needed to map options in customSelect */
    const additionalOptions = [
        { name: "Registriert", status: "registered" },
        { name: "Aktiviert", status: "active" },
        { name: "Deaktiviert", status: "disabled" },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (name, value) => {
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            const response = await axios.put(`/mUsers.php/`, editedData, config);

            if (response.data.status === 1) {
                setTimeout(() => {
                    window.location.reload();
                    closeModal();
                }, 1500);
            }

            setResponse(response.data);
            setResponseTimer(true);
        } catch (error) {
            console.error("Fehler beim Updaten des Benutzers:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axios.delete("/mUsers.php", {
                data: { id: editedData.id },
                headers: config.headers,
            });

            setResponse(response.data);
            setResponseTimer(true);

            if (response.data.status === 1) {
                setTimeout(() => {
                    window.location.reload();
                    closeModal();
                }, 1500);
            }
        } catch (error) {
            console.error("Fehler beim Löschen des Bildes:", error);
        }
    };

    const handleDeleteButtonClick = () => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }
        setDeleteButtonText("Doppelklicken!");
        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("Löschen");
        }, 2000); // Reset button text after 2 seconds
    };

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div className="responseMessage_success">
                        {isResponse.message ? isResponse.message : ""}
                    </div>
                );
            }

            if (isResponse.message.length < 1) {
                return <div></div>;
            } else {
                return (
                    <div className="responseMessage_error">
                        {isResponse ? isResponse.message : ""}
                    </div>
                );
            }
        }
    };

    useEffect(() => {
        if (responseTimer) {
            const timerId = setTimeout(() => {
                setResponseTimer(null);
                setResponse({});
            }, 1500);

            // Clear the timer if the component unmounts
            return () => clearTimeout(timerId);
        }
    }, [responseTimer]); // Only run the effect when responseTimer changes

    const showDefaultStatusValue = () => {
        switch (editedData.status) {
            case 'registered':
                return ('Registriert');
            case 'active':
                return ('Aktiviert');
            case 'disabled':
                return ('Deaktiviert');
            default:
                return '';
        }
    };

    const getGroupDataForUser = () => {
        return secondData.find(item => item.id === editedData.group_id);
    };

    return (
        <div className="modalFormContent">
            <div className="modalTitleWrapper">
                {formatResponseMessage()}

                {responseTimer === null && (
                    <>
                        <h2 className="modalTitleLeft">Benutzer bearbeiten:</h2>
                        <div className="modalTitleRight">
                            {formatName(initialData.username)} ({initialData.id})
                        </div>
                    </>
                )}
            </div>

            <div className="editModalContentWrapper">
                <div className="editModalContent">

                    <div className="editModalWrapper">
                        <div className="editModalEntryUser">

                            <div className="innerEntry">
                                <div className="entryLabel">Benutzername:</div>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={editedData.username || ""}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="innerEntry">
                                <div className="entryLabel">Gruppe:</div>
                                <div style={{ width: "100%" }}>
                                    <CustomSelect
                                        optionArray={secondData}
                                        onSelect={(selectedOption) =>
                                            handleSelectChange("group_id", selectedOption)
                                        }
                                        defaultValue={editedData.group_id && getGroupDataForUser().name}
                                        optionKey="group"
                                    />
                                </div>
                            </div>

                            <div className="innerEntry">
                                <div className="entryLabel">Vorname:</div>
                                <input
                                    type="text"
                                    id="firstname"
                                    name="firstname"
                                    value={editedData.firstname || ""}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="innerEntry">
                                <div className="entryLabel">Nachname:</div>
                                <input
                                    type="text"
                                    id="lastname"
                                    name="lastname"
                                    value={editedData.lastname || ""}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="innerEntry">
                                <div className="entryLabel">Status:</div>
                                <div style={{ width: "100%" }}>
                                    <CustomSelect
                                        optionArray={additionalOptions}
                                        onSelect={(selectedOption) =>
                                            handleSelectChange("status", selectedOption)
                                        }
                                        defaultValue={showDefaultStatusValue}
                                        optionKey="status"
                                    />
                                </div>
                            </div>

                            <div className="innerEntry">
                                <div className="entryLabel">E-Mail:</div>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={editedData.email || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                    </div>


                </div>
            </div>

            <div className="editModalButtons">
                <button className="appButtonSave" onClick={handleSave}>
                    Speichern
                </button>
                <button
                    className="appButtonDelete"
                    onDoubleClick={handleDelete}
                    onClick={handleDeleteButtonClick}
                >
                    {deleteButtonText}
                </button>
            </div>
        </div>
    );
};

export default EditUserModal;