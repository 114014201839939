const formatName = (name) => {
    // Check that the name is not empty
    if (!name || typeof name !== 'string') {
        return '——';
    }

    // Convert the first letter of the name to upper case
    return name.charAt(0).toUpperCase() + name.slice(1);

};

export default formatName;