// Pagination.js
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {

    const handleNextClick = (event) => {
        event.preventDefault();
        onPageChange(Math.min(currentPage + 1, totalPages));

    };

    const handlePrevClick = (event) => {
        event.preventDefault();
        onPageChange(Math.max(currentPage - 1, 1));
    };

    return (
        <div className="pagination">
            <button
                title={currentPage === 1 ? ('Du bist bereits auf Seite 1') : ('Vorherige Seite')}
                className="appButtonNormal"
                onClick={handlePrevClick}
                disabled={currentPage === 1}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>

            <div className="pagination-text">{`Seite ${currentPage} von ${totalPages}`}</div>

            <button
                title={currentPage === totalPages ? ('Keine weiteren Seiten vorhanden') : ('Nächste Seite')}
                className="appButtonNormal"
                onClick={handleNextClick}
                disabled={currentPage === totalPages}
            >
                <FontAwesomeIcon icon={faArrowRight} />
            </button>
        </div>
    );
};

export default Pagination;