import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Register() {
    const navigate = useNavigate();
    const [isResponse, setResponse] = useState({});
    const [isError, setError] = useState("");
    const [inputs, setInputs] = useState({
        'username': '',
        'password': '',
        'email': ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs(prevInputs => ({ ...prevInputs, [name]: value }));
    }

    const getLowerCaseUserName = (nameProp) => {
        if (nameProp && inputs.username.length > 1) {
            return nameProp.toLowerCase();
        }
    }

    const validatePassword = (password) => {
        // Password must contain at least one uppercase letter, one special character, and one number
        if (!/[A-Z]/.test(password) || !/[0-9]/.test(password) || !/[^a-zA-Z0-9]/.test(password)) {
            return false;
        }

        // Password must be at least 5 characters long
        if (password.length < 5) {
            return false;
        }

        return true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (inputs.username === "" || inputs.password === "" || inputs.email === "") {
            setError("All fields are required!");
            return;
        }

        if (!validatePassword(inputs.password)) {
            setError("Passwort zu schwach! Es muss mindestens ein Großbuchstabe, ein Sonderzeichen, eine Zahl und länger als 5 Zeichen sein");
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: getLowerCaseUserName(inputs.username), password: inputs.password, email: inputs.email })
        };

        // fetch('http://localhost:80/react/api/register.php', requestOptions)
        fetch('https://api.seelenwesen.de/register.php', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setResponse(data)
                if (data.message === "Account wurde erfolgreich erstellt!" || data.status === 1) {
                    setTimeout(function () {
                        navigate("/login")
                    }, 3000)
                }

            }).catch((err) => {
            console.log(err);
        })
    }

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div
                        className="responseMessage_success"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }

            if (isResponse.message.length < 1) {
                return <div></div>
            } else {
                return (
                    <div
                        className="responseMessage_error"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }
        }
    }

    return (
        <>
            <div className="contentWrapper">
                <form className="loginForm" onSubmit={handleSubmit}>
                    <div className="loginFormContent">
                        <h1 className="loginTitle">Account erstellen</h1>
                        {formatResponseMessage()}
                        {isError && <div className="responseMessage_error">{isError}</div>}
                        <div className="inputWrapper">
                            <div className="inputEntry">
                                <label className="formLabel">Benutzername</label>
                                <input className="" name="username" type="text" value={inputs.username} onChange={handleChange} required />
                            </div>
                            <div className="inputEntry">
                                <label className="formLabel">Passwort</label>
                                <input className="" name="password" type="password" value={inputs.password} onChange={handleChange} required />
                            </div>
                            <div className="inputEntry">
                                <label className="formLabel">Email</label>
                                <input className="" name="email" type="email" value={inputs.email} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="formButtonWrapper">
                            <button name="update" className="submitButton" type="submit">Registrieren</button>
                            <div className="formButtonRegisterWrapper">
                                <div className="registerInfo">
                                    {`Du hast schon einen Account?`}
                                </div>
                                <div className="registerInfo">
                                    <Link className="registerLink" to={'../login'}>Zurück zum Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}