import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

export default function ForgottenPassword() {
    const navigate = useNavigate();
    const [isResponse, setResponse] = useState({})
    const [isMessageSend, setMessageSend] = useState(false)
    const [inputs, setInputs] = useState(
        {
            'email': '',
        }
    );

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputs.email !== "") {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: inputs.email })
            };

            // fetch('http://localhost:80/react/api/forgottenpassword.php', requestOptions)
            fetch('https://api.seelenwesen.de/forgottenpassword.php', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setResponse(data)
                    if(data.status === 1) {
                        setMessageSend(true)
                        setTimeout(function(){
                            navigate("/login")
                        }, 3000)
                    }

                }).catch((err) => {
                console.log(err);
            })

        } else {
            console.log('Bitte gib deine E-Mail Adresse an!')
        }
    }


    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div
                        className="responseMessage_success"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }

            if (isResponse.message.length < 1) {
                return <div></div>
            } else {
                return (
                    <div
                        className="responseMessage_error"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }
        }
    }


    return (
        <>
            <div className="contentWrapper">
                <form className="loginForm" onSubmit={handleSubmit}>

                    <div className="loginFormContent">
                        <h1 className="loginTitle">Passwort zurücksetzen</h1>
                        {formatResponseMessage()}

                        <div className="inputWrapper">
                            <div style={{textAlign: 'center', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                Gib deine E-Mail Adresse ein und sende das Formular ab. Du erhälst dann eine E-Mail
                                mit der weiteren Vorgehensweise.
                            </div>
                        </div>

                        <div className="inputWrapper">
                            <div className="inputEntry">
                                <label className="formLabel">Email</label>
                                <input className="" name="email" type="email" disabled={isMessageSend} value={inputs.email}
                                       onChange={handleChange} required/>
                            </div>
                        </div>

                        <div className="formButtonWrapper">
                            <button className="submitButton" disabled={isMessageSend} type="submit">Passwort an E-Mail senden</button>
                            <div className="formButtonRegisterWrapper">
                                <Link className="registerLink" to={'../login'}>Zurück zum Login</Link>
                            </div>
                        </div>
                    </div>

                </form>
            </div>

        </>
    )
}