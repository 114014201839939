import React, {useEffect, useRef, useState} from "react";
import './Comments.css';
import TimestampConverter from "../../Element/Utilities/TimestampConverter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {useLocalStorage} from "usehooks-ts";
import { useCookies } from "react-cookie";
import GetValueFromForeignKey from "../../Element/Utilities/GetValueFromForeignKey";
import {Link} from "react-router-dom";

function Comments({currentUserData, reloadAfterChanges}) {
    const [isUser] = useLocalStorage("isUser", "");
    const [isToken] = useLocalStorage("isToken", "");
    const [isChanged, setChanged] = useState(false);
    const [isComments, setComments] = useState({});
    const [isLoadingComments, setLoadingComments] = useState(true);
    const deleteButtonTimerRef = useRef(null);
    const [isSelectedCommentToDelete, setSelectedCommentToDelete] = useState(null);
    const [isDeleteButtonText, setDeleteButtonText] = useState("");
    const [isResponse, setResponse] = useState({});

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //

    useEffect(() => {
        axios
            .get(`/userComments.php`)
            .then((response) => {
                setComments(response.data);
                setLoadingComments(false);
                setChanged(false)

            })
            .catch((error) => {
                console.error('Fehler beim Laden der Kategorien: ', error);
                setLoadingComments(false);
            });
    }, [isChanged]);

    const handleImageError = (comment) => {
        console.log("Fehler beim Laden des Bildes:", comment.avatar_path);

        // Setzen Sie den Wert des avatar_path des Kommentars auf 0
        const updatedComment = { ...comment, avatar_path: "0" };
        // Aktualisieren Sie den Kommentar in Ihrem State
        const updatedComments = isComments.map((c) => (c.id === comment.id ? updatedComment : c));
        setComments(updatedComments);
    };

    const handleDeleteButtonClick = (comment) => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        if (isSelectedCommentToDelete === comment) {
            deleteComment(comment);
        } else {
            setDeleteButtonText("2x");
            setSelectedCommentToDelete(comment);
        }

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("");
            setSelectedCommentToDelete(null); // Bild zurücksetzen
        }, 2000);
    };

    async function deleteComment(commentData) {
        try {
            let response = {};

            if (currentUserData.perm_comment === 1 || currentUserData.groupId === 1) {
                response = await axios.delete("/mComments.php", {
                    data: { id: commentData.id },
                    headers: config.headers,
                });
            } else {
                response = await axios.delete("/registeredComments.php", {
                    data: { id: commentData.id },
                    headers: config.headers,
                });
            }

            setResponse(response.data);

            // Nach erfolgreichem Kommentar-Delete die Kommentare neu abrufen
            axios
                .get(`/userComments.php`)
                .then((response) => {
                    setComments(response.data);
                    reloadAfterChanges();
                })
                .catch((error) => {
                    console.error("Fehler beim Laden der Kommentare: ", error);
                });
        } catch (error) {
            console.error("Fehler beim Löschen des Kommentars: ", error);
        }
    }

    return (
        <>
            <div className="contentWrapper">
                <div className="normalForm" style={{maxWidth: '40rem', width: '100%'}}>
                    <div className="loginFormContent">
                        <h1 className="loginTitle">
                            Kommentare
                        </h1>

                        <div className="formContainer">
                            <div className="innerEditWrapper" style={{background: '#0000001a'}}>

                                <div className="overViewPreviewCommentsEntryWrapper">
                                    {isComments.length > 0 ? (
                                        isComments.map((comment) => (
                                            <div className={"overViewUserCommentEntryWrapper"}>

                                                <Link
                                                    key={`link ${comment.id}`}
                                                    value={comment.id}
                                                    to={`/category/${comment.category_id}`}
                                                >
                                                    <img
                                                        className="overViewCommentsImage"
                                                        src={comment.thumbnail_path}
                                                        alt={'thumbnail_image'}
                                                    />
                                                </Link>

                                                <div key={comment.id} className="overViewUserCommentEntry">
                                                    <div className={`overViewUserCommentHeader`}>

                                                    <div
                                                            className={`overViewUserCommentAuthor ${
                                                                comment.user_id === currentUserData && currentUserData.user_id ? "is-author" : ""
                                                            }`}
                                                        >
                                                            {(comment.avatar_path === "0" || comment.avatar_path === null) ? (
                                                                <div className="overViewUserCommentIcon">
                                                                    {comment.user_name.substring(0, 2).toUpperCase()}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <img
                                                                        className="overViewUserCommentIcon"
                                                                        src={comment.avatar_path}
                                                                        alt={'1.png'}
                                                                        onError={() => handleImageError(comment)}
                                                                    />
                                                                </>
                                                            )}
                                                            <div className="overViewUserInfo">
                                                                <div
                                                                    className="overViewUserName">{comment.user_name.charAt(0).toUpperCase() + comment.user_name.slice(1)}</div>
                                                                <div className="overViewUserTime">
                                                                    <TimestampConverter
                                                                        timestamp={comment.creation_date}
                                                                        showDateAsString={true}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(currentUserData.user_id === comment.user_id) || (currentUserData.perm_comment === 1) ? (
                                                            <div className="trashIconWrapper">
                                                                <button
                                                                    className="appButtonTrashIcon"
                                                                    onClick={() => handleDeleteButtonClick(comment)}
                                                                >
                                                                    {isSelectedCommentToDelete === comment ? (
                                                                        <div>{isDeleteButtonText}</div>
                                                                    ) : (
                                                                        <FontAwesomeIcon icon={faTrash}/>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div className="trashIconWrapper"></div>
                                                        )}
                                                    </div>
                                                    <div className="overViewUserCommentInfo">
                                                        <div className="overViewUserCommentMessage">{comment.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="messageWrapper">
                                            <div className="infoMessage">Keine Kommentare vorhanden</div>
                                        </div>
                                    )}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Comments;