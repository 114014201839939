import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import './Login.css';

function Login() {
    const signIn = useSignIn();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isError, setError] = useState("");
    const [isResponse, setResponse] = useState({});
    const navigate = useNavigate();
    const [isUser, setIsUser] = useLocalStorage('isUser', '');
    const [isRandomTitle, setRandomTitle] = useState(false);
    const [randomTitleMessage, setRandomTitleMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isToken, setToken] = useLocalStorage('isToken', '');

    useEffect(() => {
        if (isRandomTitle === false) {
            randomLoginButtonTitle();
        }
    }, []);

    const handleUsernameChange = event => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = event => {
        setPassword(event.target.value)
    }

    const randomLoginButtonTitle = () => {
        const titles = ['Los gehts!', 'Bereit?', 'Zeig mir neues', 'Lass uns loslegen', 'Lass die Seele baumeln']
        setRandomTitleMessage(titles[Math.floor(Math.random()*titles.length)])
        setRandomTitle(true)
    }

    const handleSubmit = event => {
        event.preventDefault();

        document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');

        if (username !== "" && password !== "") {
            let headers = {
                "Accept": "*/*",
                "Content-Type": "*/*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "*",
            };
            let Data = {
                username: username,
                password: password,
                loginToken: isToken,
            };

            fetch('https://api.seelenwesen.de/login.php', { method: "POST", headers: headers, body: JSON.stringify(Data)})
                .then((response) => response.json())
                .then((response) => {
                    setResponse(response)
                    if(response.message === "Erfolgreich angemeldet" && response.status === 1) {
                        if(signIn({
                            authState: { username: response.username.toLowerCase() },
                            auth: {
                                token: response.jwt,
                                type: 'Bearer'
                            }
                        })) {} else { /* Throw error */ }

                        setToken(response.loginToken)
                        setIsUser(response.username.toLowerCase())
                        setIsLoading(false)

                        // Timer for response message
                        setTimeout(function(){
                            setResponse({}); // Clear response message
                            navigate("/")
                            window.location.reload()
                        }, 3000)
                    } else {
                        setResponse(response)
                        setIsLoading(false)

                        setTimeout(function () {
                            setResponse({});
                        }, 3000);
                    }

                }).catch((err) => {
                    setError(err);
                    setResponse({ status: 0, message: "Fehler: Bitte versuche es später noch einmal" });
                    setIsLoading(false);

                    setTimeout(function () {
                        setResponse({});
                    }, 3000);
                })

        } else {
            setError("All fields are required!")
        }
    }

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.message === "Erfolgreich angemeldet") {

                if (isLoading === false) {
                    setIsLoading(true)
                }
                return (
                    <div
                        className="responseMessage_success"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }

            if (isResponse.message.length < 1) {
                return <div></div>
            } else {
                return (
                    <div
                        className="responseMessage_error"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }
        }
    }

    return (
        <>
            {randomTitleMessage.length > 1 && (
                <div className="contentWrapper">
                    <form className="loginForm" onSubmit={handleSubmit}>

                        <div className="loginFormContent">

                            {isLoading === true ? (
                                <div className="loginTitle">
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="loginTitle">
                                    <h1>Einloggen</h1>
                                </div>
                            )}

                            <div className="formContainer">
                                <div className="innerEditWrapper" style={{background: '#0000001a'}}>

                                    {formatResponseMessage()}

                                    {isError && (
                                        <div className="responseMessage_success">{isError}</div>
                                    )}

                                    <div className="inputWrapper">
                                        <div className="inputEntry">
                                            <label className="formLabel">Benutzername</label>
                                            <input className="" type="text" value={username}
                                                   onChange={handleUsernameChange}
                                                   required/>
                                        </div>
                                        <div className="inputEntry">
                                            <label className="formLabel">Passwort</label>
                                            <input className="" type="password" value={password}
                                                   onChange={handlePasswordChange}
                                                   required/>
                                        </div>
                                    </div>

                                    <div className="formButtonWrapper">

                                        <button className="submitButton" type="submit">{randomTitleMessage}</button>

                                        <div className="formButtonRegisterWrapper">
                                            <div className="registerInfo">
                                                <div className="registerInfoAccountCreate">
                                                    {`Du hast noch keinen Account? `}
                                                    <Link className="registerLink" to={'../register'}>Account
                                                        erstellen</Link>
                                                </div>
                                            </div>
                                            <div className="registerInfo">
                                                <div className="registerInfoPasswordReset">
                                                    {`Passwort vergessen? `}
                                                    <Link className="registerLink" to={'../forgotten-password'}>Neues
                                                        Passwort anfordern</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            )}

        </>
    );
}

export default Login;