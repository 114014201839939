import React from 'react';
import './FooterSites.css';
import EmailLink from "../../Element/EmailLink/EmailLink";

const Impressum = () => {
    return (
        <div className="contentWrapper">
            <div className="normalForm" style={{maxWidth: '40rem', width: '100%'}}>
                <div className="loginFormContent">
                    <h1 className="loginTitle">
                        Impressum
                    </h1>

                    <div className="formContainer">
                        <div className="innerEditWrapper" style={{background: '#0000001a'}}>
                            <div>

                                <div className="entryContainer">
                                    <h2>Verantwortlich für den Inhalt dieser Website gemäß § 5 TMG:</h2>
                                    <div className="innerTextWrapper">
                                        <p>Silvia Riedmaier</p>
                                        <div className="innerTextInfo">(Bitte kontaktieren Sie uns per E-Mail, um die
                                            vollständige Adresse zu erhalten.)
                                        </div>
                                    </div>
                                </div>

                                <div className="entryContainer">
                                <h2>Kontakt:</h2>
                                    <div className="innerTextWrapper">
                                        <div>E-Mail: <EmailLink mailTo={'support@seelenwesen.de'}/></div>
                                    </div>
                                </div>

                                <div className="entryContainer">
                                    <h2>Haftungsausschluss:</h2>
                                    <div className="innerTextWrapper">
                                        <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
                                    </div>
                                </div>

                                <div className="entryContainer">
                                    <h2>Urheberrecht:</h2>
                                    <div className="innerTextWrapper">
                                        <p>Die durch den Seitenbetreiber erstellten Inhalte und Werke auf dieser Website unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.</p>
                                    </div>
                                </div>

                                <div className="entryContainer">
                                    <h2>Datenschutz:</h2>
                                    <div className="innerTextWrapper">
                                        <p>Die Nutzung dieser Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Weitere Informationen zum Datenschutz finden Sie in unserer Datenschutzerklärung.</p>
                                    </div>
                                </div>

                                <div className="innerDisclaimerWrapper">
                                    <div className="innerTextInfo">
                                        <p>Quelle: Disclaimer von eRecht24, dem Portal zum Internetrecht von Rechtsanwalt Sören Siebert.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

};

export default Impressum;
