import React, { useContext, useRef, useState, useEffect } from "react";
import './Gallery.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faThumbsUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import ImagePreview from "../../Element/ImagePreview/ImagePreview";
import GetValueFromForeignKey from "../../Element/Utilities/GetValueFromForeignKey";
import { UserContext } from "../../App";
import {Link, useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "usehooks-ts";
import Tooltip from "../../Element/Tooltip/Tooltip";

export default function Gallery({ images, topImages, categories, reloadAfterChanges, isResponsive }) {
    const user = useContext(UserContext);
    const [isToken, setToken] = useLocalStorage('isToken', '');
    const chunkSize = 1;
    const [showPreview, setShowPreview] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const deleteButtonTimerRef = useRef(null);
    const [isDeleteButtonText, setDeleteButtonText] = useState("");
    const [isSelectedImageToDelete, setSelectedImageToDelete] = useState(null);
    const [showMoreButton, setShowMoreButton] = useState(true);
    const [loadedImages, setLoadedImages] = useState(20);
    const [isLikedImages, setLikedImages] = useState([]);
    const [isChanged, setChanged] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Neuen State für die Top 20 Vorschau
    const [showTop20Preview, setShowTop20Preview] = useState(false);
    const [selectedTop20ImageIndex, setSelectedTop20ImageIndex] = useState(null);

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${user.username}`,
            LToken: `${isToken}`,
        },
    }; // --------------------------------- //

    useEffect(() => {
        const fetchLikedImages = async () => {
            try {
                let response;
                if (user && isToken) {
                    // Authentifizierter GET-Request
                    response = await axios.get("/userLikes.php", {
                        headers: {
                            Authorization: `${token}`,
                            RequestUser: `${user.username}`,
                            LToken: `${isToken}`,
                        },
                    });
                } else {
                    // Nicht authentifizierter GET-Request
                    response = await axios.get("/userLikes.php");
                }

                setLikedImages(response.data);
                setChanged(false);
            } catch (error) {
                console.error("Fehler beim Abrufen der Bilder likes: ", error);
            }
        };

        fetchLikedImages();
    }, [user, isToken, isChanged]);

    const checkIfUserHasPermission = (permission) =>
        user?.group_id === 1 ?
            true :
            (user?.[`perm_${permission}`] === 1 && user.status === "active") || false;

    const openPreview = (columnIndex, indexInColumn) => {
        const globalIndex = columnIndex * chunkSize + indexInColumn;
        setSelectedImageIndex(globalIndex);
        setShowPreview(true);
    };

    const closePreview = () => {
        setSelectedImageIndex(null);
        setShowPreview(false);
    };

    // Funktion zum Öffnen der Top 20 Vorschau
    const openTop20Preview = (index) => {
        setSelectedTop20ImageIndex(index);
        setShowTop20Preview(true);
    };

// Funktion zum Schließen der Top 20 Vorschau
    const closeTop20Preview = () => {
        setSelectedTop20ImageIndex(null);
        setShowTop20Preview(false);
    };

    async function deleteObject(objectData) {
        if (checkIfUserHasPermission('object')) {
            try {
                const response = await axios.delete("/mObjects.php", {
                    data: { id: objectData.id },
                    headers: config.headers,
                });

                if (response.data.status === 1) {
                    reloadAfterChanges()
                }

            } catch (error) {
                console.error("Fehler beim Löschen des Bildes: ", error);
            }
        }
    }

    const handleDeleteButtonClick = (image) => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        if (isSelectedImageToDelete === image) {
            deleteObject(image);
        } else {
            setDeleteButtonText("2x");
            setSelectedImageToDelete(image);
        }

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("");
            setSelectedImageToDelete(null); // Reset image to delete
        }, 2000);
    };

    const toggleLike = async (imageId) => {
        try {
            const existingLike = isLikedImages.find(item => item.object_id.toString() === imageId && item.hasLiked === 1);

            if (user && isToken) {
                if (existingLike) {
                    await axios.delete(`/userLikes.php`, {
                        headers: config.headers,
                        data: { object_id: imageId } // Send imageId in the request body
                    });
                    setLikedImages(isLikedImages.filter(item => item.object_id !== imageId));
                    setChanged(true)
                } else {
                    await axios.post("/userLikes.php", { object_id: imageId }, {
                        headers: config.headers,
                    });
                    setLikedImages([...isLikedImages, { object_id: imageId, hasLiked: 1 }]);
                    setChanged(true)
                }
            }

        } catch (error) {
            console.error("Fehler beim Ändern des Likes: ", error);
        }
    };

    const loadMoreImages = () => {
        const newLoadedImages = loadedImages + 20;
        if (newLoadedImages >= images.length) {
            setShowMoreButton(false);
        }
        setLoadedImages(newLoadedImages);
    };

    const visibleImages = images.slice(0, loadedImages);

    const chunkedImages = Array.isArray(visibleImages)
        ? visibleImages.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize);
            if (!resultArray[chunkIndex]) { resultArray[chunkIndex] = []; }
            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []) : [];

    // Überprüfen, ob die aktuelle URL entweder die Root-Seite oder /home ist
    const isHomePage = location.pathname === '/' || location.pathname === '/home';

    const getLikeClass = (image, isLikedImages, user, isToken) => {
        const foundItem = isLikedImages.find(item => item.object_id === parseInt(image.id));
        const userHasLiked = foundItem && foundItem.hasLiked === 1;
        const likeCount = foundItem ? foundItem.count : 0;

        if (user && isToken) {
            // User ist eingeloggt
            if (userHasLiked) {
                return 'is-liked'; // Benutzer hat selbst geliked
            } else if (likeCount > 0) {
                return 'is-not-liked'; // Andere Likes, aber nicht vom Benutzer
            } else {
                return 'has-no-likes'; // Keine Likes insgesamt
            }
        } else {
            // User ist nicht eingeloggt
            return likeCount > 0 ? 'has-likes' : 'has-no-likes';
        }
    };

    return (
        <>
            {isHomePage && (
                <div className="topImages">
                    <div className="innerContentHeader">
                        <div className="innerContentTitleWrapper">
                            <h2>
                                {"Top 20"}
                            </h2>
                            <h3 style={{color: 'white', marginTop: '0.15rem'}}>
                                {"Bilder mit den meisten Likes"}
                            </h3>
                        </div>
                    </div>

                    <div className="row">
                        {topImages && Array.isArray(topImages) && topImages.map((image, index) => (
                            <div key={image.object_id} className="column">
                                <div className="imageScaleWrapper">
                                    <div className="parallaxWrapper">
                                        <div className="imageContainer">
                                            <Link
                                                key={`link ${index}`}
                                                value={image.id}
                                                to={`/category/${image.category_id}`}
                                            >
                                                <span className="infoItemCategory">
                                                    {GetValueFromForeignKey(image.category_id, categories, 'name')}
                                                </span>
                                            </Link>

                                            {checkIfUserHasPermission('object') && (
                                                <div className="infoItemDelete">
                                                    <button
                                                        className="infoItemDeleteIcon"
                                                        onClick={() => handleDeleteButtonClick(image)}
                                                    >
                                                        {isSelectedImageToDelete === image ? (
                                                            <div>{isDeleteButtonText}</div>
                                                        ) : (
                                                            <FontAwesomeIcon icon={faTrash}/>
                                                        )}
                                                    </button>
                                                </div>
                                            )}

                                            <img
                                                src={image.image_path}
                                                width="100%"
                                                alt={`Top ${index + 1}`}
                                                onClick={() => openTop20Preview(index)}
                                            />

                                            <div className="bottomInfo">
                                                <div className={`infoItem ${image.name ? "" : "noName"}`}>
                                                    {image.name ? image.name : "Kein Titel"}
                                                </div>

                                                <div className="infoIconWrapper">
                                                    {Array.isArray(isLikedImages) && (
                                                        <div
                                                            className={`infoItem ${getLikeClass(image, isLikedImages, user, isToken)}`}
                                                            onClick={user && isToken ? () => toggleLike(image.id) : () => navigate("/login")}
                                                        >
                                                            <div style={{fontSize: '0.65rem', paddingTop: '0.15rem'}}>
                                                                {isLikedImages.find(item => item.object_id === parseInt(image.id))?.count || 0}
                                                            </div>
                                                            <FontAwesomeIcon className="infoIconLike"
                                                                             icon={faThumbsUp}/>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={`infoItem ${image.comments_count < 1 ? 'is-noComments' : ''}`}>
                                                        <div style={{fontSize: '0.65rem', paddingTop: '0.15rem'}}>
                                                            {image.comments_count || ''}
                                                        </div>
                                                        <FontAwesomeIcon className="infoIconComment" icon={faComments}/>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {showTop20Preview && (
                            <ImagePreview
                                images={topImages}
                                categories={categories}
                                selectedIndex={selectedTop20ImageIndex}
                                onClose={closeTop20Preview}
                                currentUser={user || {}}
                                reloadAfterChanges={reloadAfterChanges}
                            />
                        )}
                    </div>
                </div>
            )}

            <div className={"galleryImages"}>
                {isHomePage && (
                    <div className="innerContentHeader">
                        <div className="innerContentTitleWrapper">
                            <h2>
                                {"Neueste Bilder"}
                            </h2>
                            <h3 style={{color: 'white', marginTop: '0.15rem'}}>
                                {"Die 30 neuesten Bilder"}
                            </h3>
                        </div>
                    </div>
                )}
                <div className="row">
                {chunkedImages.map((column, columnIndex) => (
                    <div key={columnIndex} className="column">
                        {column.map((image, indexInColumn) => (
                            <div key={image.id} className="imageScaleWrapper">
                                <div className="parallaxWrapper">
                                    <div className="imageContainer">
                                        <Link
                                            key={`link ${indexInColumn}`}
                                            value={image.id}
                                            to={`/category/${image.category_id}`}
                                        >
                                        <span className="infoItemCategory">
                                            {GetValueFromForeignKey(image.category_id, categories, 'name')}
                                        </span>
                                        </Link>

                                        {checkIfUserHasPermission('object') && (
                                            <div className="infoItemDelete">
                                                <button
                                                    className="infoItemDeleteIcon"
                                                    onClick={() => handleDeleteButtonClick(image)}
                                                >
                                                    {isSelectedImageToDelete === image ? (
                                                        <div>{isDeleteButtonText}</div>
                                                    ) : (
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    )}
                                                </button>
                                            </div>
                                        )}

                                        <img
                                            src={image.thumbnail_path}
                                            width="100%"
                                            alt={`${indexInColumn + 1}`}
                                            onClick={() => openPreview(columnIndex, indexInColumn)}
                                        />

                                        <div className="bottomInfo">
                                            <div
                                                className={`infoItem ${image.name ? "" : "noName"}`}>{image.name ? image.name : "Kein Titel"}</div>

                                            <div className="infoIconWrapper">
                                                {Array.isArray(isLikedImages) && (
                                                    user && isToken ? (
                                                        <div
                                                            className={`infoItem ${getLikeClass(image, isLikedImages, user, isToken)}`}
                                                            onClick={() => toggleLike(image.id)}
                                                        >
                                                            <div style={{fontSize: '0.65rem', paddingTop: '0.15rem'}}>
                                                                {isLikedImages.find(item => item.object_id === parseInt(image.id))?.count || ''}
                                                            </div>
                                                            <FontAwesomeIcon className="infoIconLike"
                                                                             icon={faThumbsUp}/>
                                                        </div>
                                                    ) : (
                                                        <Tooltip
                                                            transition={'false'}
                                                            tooltipText={'Du musst dich einloggen um likes zu geben'}
                                                        >
                                                            <div
                                                                className={`infoItem ${getLikeClass(image, isLikedImages, user, isToken)}`}
                                                                onClick={() => navigate("/login")}
                                                            >
                                                                <div style={{
                                                                    fontSize: '0.65rem',
                                                                    paddingTop: '0.15rem'
                                                                }}>
                                                                    {isLikedImages.find(item => item.object_id === parseInt(image.id))?.count || ''}
                                                                </div>
                                                                <FontAwesomeIcon className="infoIconLike"
                                                                                 icon={faThumbsUp}/>
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                )}

                                                <div
                                                    className={`infoItem ${image.comments_count < 1 ? 'is-noComments' : ''}`}>
                                                    <div style={{fontSize: '0.65rem', paddingTop: '0.15rem'}}>
                                                        {image.comments_count || ''}
                                                    </div>
                                                    <FontAwesomeIcon className="infoIconComment" icon={faComments}/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}

                    {showMoreButton && (loadedImages < images.length) && (
                        <div className="loadMoreButtonWrapper">
                            <button className="loadMoreButton" onClick={loadMoreImages}>
                                Mehr anzeigen
                            </button>
                        </div>
                    )}

                    {showPreview && (
                        <ImagePreview
                            images={images}
                            categories={categories}
                            selectedIndex={selectedImageIndex}
                            onClose={closePreview}
                            currentUser={user || {}}
                            reloadAfterChanges={reloadAfterChanges}
                        />
                    )}
                </div>
            </div>

        </>
    );
}
