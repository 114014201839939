import Table from "../Default/Table";

const columns = [
    { label: "ID", accessor: "id", sortable: true },
    { label: "Name", accessor: "name", sortable: true },
    { label: "Beschreibung", accessor: "description", sortable: true },
    { label: "Erstellt am", accessor: "creation_date", sortable: true },
    { label: "Aktionen", accessor: "actions" }
];

const ManageCategoryTable = ({data, handleOpenModal, type, reloadAfterChanges, apiConfig}) => {
    return (
        <>
            <Table
                caption=""
                data={data}
                type={type}
                handleOpenModal={handleOpenModal}
                columns={columns}
                itemsPerPage={10}
                reloadAfterChanges={reloadAfterChanges}
                apiConfig={apiConfig}
            />
        </>
    );
};

export default ManageCategoryTable;
