const DataSorter = ({ dataArray, sortKey, order = "desc" }) => {
    const sortFunction = (a, b) => {
        const valueA = a[sortKey];
        const valueB = b[sortKey];

        const dataTypeA = typeof valueA;
        const dataTypeB = typeof valueB;

        // Add special sorting logic for start page (ID: 0)
        if (a.id === 0) {
            return -1; // a (start page object) should always come first
        } else if (b.id === 0) {
            return 1; // b (start page object) should always be first
        }

        // Prioritize sorting based on numbers
        if (dataTypeA === 'number' && dataTypeB === 'number') {
            return order === 'asc' ? valueA - valueB : valueB - valueA;
        }

        // If one of them is a number, prioritize it
        if (dataTypeA === 'number') {
            return -1;
        }

        if (dataTypeB === 'number') {
            return 1;
        }

        // For other types, fallback to string comparison
        return order === 'asc' ? valueA.toString().localeCompare(valueB.toString()) : valueB.toString().localeCompare(valueA.toString());
    };

    return [...dataArray].sort(sortFunction);
};

export default DataSorter;