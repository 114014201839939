import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const SearchInput = ({ data, onSearch, style }) => {
    const [inputValue, setInputValue] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();

        const results = data.filter((item) => {
            // Search each key value pair of the object
            for (const key in item) {
                const value = item[key].toString().toLowerCase();
                if (value.includes(term)) {
                    return true;
                }
            }
            return false;
        });

        onSearch(results);
        setInputValue(event.target.value); // Update inputValue State
    };

    // Get current url location
    const location = useLocation();

    useEffect(() => {
        // Reset the input field if the location changes
        setInputValue("");
        setSearchTerm("");
    }, [location]);

    return (
        <div>
            <input
                style={style}
                type="text"
                placeholder="Suche eingeben"
                value={inputValue}
                onChange={handleSearch}
            />
        </div>
    );
};

export default SearchInput;