import React, {useEffect, useState} from "react";
import CreateCategory from "../../Element/Categories/CreateCategory";
import {useLocalStorage} from "usehooks-ts";
import {useCookies} from "react-cookie";
import axios from "axios";
import ManageCategoryTable from "../Tables/ManagedTables/ManageCategoryTable";

export default function ManageCategories({reloadAfterChanges}) {
    const [isUser] = useLocalStorage('isUser', '')
    const [isToken] = useLocalStorage('isToken', '')
    const [isCategories, setCategories] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isChanged, setChanged] = useState(false)

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`}};
    // --------------------------------- //

    useEffect(() => {
        const fetchData = async () => {
            if (isLoading || isChanged) {
                try {
                    const response = await axios.get('/mCategories.php', config);
                    setCategories(response.data);
                    setLoading(false);
                    setChanged(false);

                } catch (error) {
                    // Handle error, if needed
                    console.error('Fehler beim Laden der Kategorien: ', error);
                    setLoading(false);
                    setChanged(false);
                }
            }
        };

        fetchData();
    }, [isLoading, isChanged]);

    const fetchAfterChanges = () => {
        reloadAfterChanges()
        setChanged(true)
        setLoading(true)
    }

    return (
        <>
            <div className="contentWrapper">
                <div className="normalForm">
                    <div className="loginFormContent">
                        <h1 className="loginTitle">
                            Kategorien verwalten
                        </h1>

                        <div className="formContainer">
                            <CreateCategory fetchAfterChanges={fetchAfterChanges} />

                            {isCategories.length > 0 && !isChanged  && !isLoading ? (
                                <ManageCategoryTable
                                    reloadAfterChanges={fetchAfterChanges}
                                    data={isCategories}
                                    type={"ManageCategoryTable"}
                                    apiConfig={config}
                                />
                            ) : (
                                <div className="messageWrapper">
                                    <div className="infoMessage">
                                        Es wurden keine Kategorien gefunden
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}
