import {Routes, Route, useNavigate, useLocation, Link} from 'react-router-dom';
import './App.css';
import './Checkbox.css';
import React, {useEffect, useRef, useState} from "react";
import Register from "./components/Register/Register";
import Home from "./components/Home/Home";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import Login from "./components/Login/Login";
import {useCookies} from "react-cookie";
import {jwtDecode} from "jwt-decode";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import {useLocalStorage} from "usehooks-ts";
import {fab, faPagelines} from '@fortawesome/free-brands-svg-icons'
import {faArrowUp, faComments, faHome} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import ManageUsers from "./components/Admin/ManageUsers";
import Header from "./components/Header/Header";
import axios from "axios";
import ForgottenPassword from "./components/ForgottenPassword/ForgottenPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import {useModal} from "./Element/Modal/ModalProvider";
import ManageImages from "./components/Admin/ManageImages";
import ManageCategories from "./components/Admin/ManageCategories";
import Navigation from "./components/Navigation/Navigation";
import ManageComments from "./components/Admin/ManageComments";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ManageGroups from "./components/Admin/ManageGroups";
import EditProfile from "./components/EditProfile/EditProfile";
import ActivateAccount from "./components/ActivateAccount/ActivateAccount";
import Impressum from "./components/FooterSites/Impressum";
import PrivacyPolicy from "./components/FooterSites/PrivacyPolicy";
import AboutMe from "./components/AboutMe/AboutMe";
import Comments from "./components/Comments/Comments";

library.add(fab, faHome, faPagelines, faComments)

export const UserContext = React.createContext(null);

const App = () => {

    const signOut = useSignOut();
    const signOutFunction = () => signOut()
    const { isModalOpen } = useModal();
    const navigate = useNavigate();
    const [isObjects, setObjects] = useState({});
    const [isTopObjects, setTopObjects] = useState({});
    const [isCategories, setCategories] = useState({});
    const [isChanged, setChanged] = useState(false);
    const [isScrollButtonVisible, setScrollButtonVisible] = useState(false)
    const [isScrollNavigationVisible, setScrollNavigationVisible] = useState(false)
    const [isLoadingCategories, setLoadingCategories] = useState(true);
    const [isLoadingTopGallery, setLoadingTopGallery] = useState(true);
    const [isUser, setIsUser] = useLocalStorage('isUser', '')
    const [isToken, setToken] = useLocalStorage('isToken', '')

    // -------- DISABLE SIDEBAR ON WINDOW WIDTH ---------- //
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const thresholdWidth = 1025;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Event-Listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove Event-Listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // -------- DISABLE SIDEBAR ON WINDOW WIDTH ---------- //


    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth ? cookies._auth : '';
    const config = {headers: { Authorization: `${token}` }};
    // --------------------------------- //


    const checkJwtExpired = () => {
        if (cookies && token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now().toString()

            const secondsDateNow = Math.floor(currentTime / 1000);
            const secondsDateToken = decodedToken.exp

            if ((secondsDateToken - secondsDateNow) < 10) {
                setIsUser('')
                setToken('')
                signOut()
                navigate("/home")
                signOutFunction()
                window.location.reload()
            }
        }
    }

    // -------- authUser ---------- //
    const [currentUserData, setCurrentUserData] = useState({})
    const [isAuthorized, setAuthorized] = useState(false)
    const [isDisabled, setDisabled] = useState(false)


    // Check current user permissions
    const checkIfUserHasPermission = (permission) =>
        currentUserData?.group_id === 1
            ? true
            : (currentUserData?.[`perm_${permission}`] === 1 && currentUserData.status === "active") || false;

    async function authorizeUser() {
        try {
            await axios.post(`/authUser.php`, { username: isUser, loginToken: isToken }, config)
                .then(function (response) {
                    setCurrentUserData(response.data);
                    setAuthorized(true)
                    setDisabled(response.data.status === 'disabled')
                });
        } catch (error) {
            console.error('Fehler bei der Autorisierung:', error);
        }
    }

    useEffect(() => {
        if(isAuthorized === true && isDisabled === true) {
            setIsUser('')
            setToken('')
            signOut()
            navigate("/")

            setTimeout(function(){
                window.location.reload()
            }, 1500)
        }
    }, [currentUserData, isAuthorized]);

    useEffect(() => {
        if(isAuthorized === false && isUser && isToken) {
            authorizeUser()
        }
    },[currentUserData, isAuthorized])

    useEffect(() => {
        checkJwtExpired()
    },)

    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const categoryId = pathSegments.pop();

    useEffect(() => {
        axios
            .get(`/galleryObjects.php/${categoryId}`)
            .then((response) => {
                setObjects(response.data);
                setChanged(false)

            })
            .catch((error) => {
                console.error('Fehler beim Laden der Bilder: ', error);
            });
    }, [categoryId, isChanged, isModalOpen]);

    useEffect(() => {
        axios
            .get(`/topGallery.php/${categoryId}`)
            .then((response) => {
                setTopObjects(response.data);
                setLoadingTopGallery(false);
                setChanged(false)

            })
            .catch((error) => {
                console.error('Fehler beim Laden der Top Gallery: ', error);
            });
    }, [categoryId, isChanged, isModalOpen]);


    useEffect(() => {
        axios
            .get(`/userCategories.php`)
            .then((response) => {
                setCategories(response.data);
                setLoadingCategories(false);
                setChanged(false)

            })
            .catch((error) => {
                console.error('Fehler beim Laden der Kategorien: ', error);
                setLoadingCategories(false);
            });
    }, [categoryId, isChanged, isModalOpen]);

    const reloadAfterChanges = () => {
        setChanged(true)
    }

    const handleScrollButton = (e) => {
        // Get scroll position
        const currentPosition = e.target.scrollTop;
        const screenHeight = window.innerHeight;
        setScrollButtonVisible(currentPosition > screenHeight);

        if (currentPosition >= 60) {
            if (!isScrollNavigationVisible) {
                setScrollNavigationVisible(true);
            }
        } else {
            if (isScrollNavigationVisible) {
                setScrollNavigationVisible(false);
            }
        }

    };

    // Handle ScrollToTopButton for ContentSegmentRight
    const targetDivRef = useRef(null);
    const scrollToTop = () => {
        const targetDiv = targetDivRef.current;

        if (targetDiv) {
            targetDiv.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    return (
        <UserContext.Provider value={currentUserData}>
            <div className="App">
                    <Header isResponsive={windowWidth < thresholdWidth ? "true" : "false"}/>

                <div className="contentSegmentWrapper">
                    {windowWidth > thresholdWidth && (
                        <div className="contentSegmentLeft">
                            <Navigation isChanged={isChanged}/>
                        </div>
                    )}

                    <div className="contentSegmentRight" onScroll={handleScrollButton} ref={targetDivRef}>
                        <Routes>
                            <Route path="/" element={
                                <RequireAuth loginPath="/login" fallbackPath="/home">
                                    <Home
                                        isObjects={isObjects}
                                        isTopObjects={isTopObjects}
                                        isCategories={isCategories}
                                        categoryId={categoryId}
                                        isLoadingCategories={isLoadingCategories}
                                        reloadAfterChanges={reloadAfterChanges}
                                    />
                                </RequireAuth>
                            }/>

                            <Route path="/category/:id" element={
                                <Home
                                    isObjects={isObjects}
                                    isTopObjects={isTopObjects}
                                    isCategories={isCategories}
                                    categoryId={categoryId}
                                    isLoadingCategories={isLoadingCategories}
                                    reloadAfterChanges={reloadAfterChanges}
                                />
                            }/>

                            <Route path="/home" element={
                                <Home
                                    isObjects={isObjects}
                                    isTopObjects={isTopObjects}
                                    isCategories={isCategories}
                                    categoryId={categoryId}
                                    isLoadingCategories={isLoadingCategories}
                                    reloadAfterChanges={reloadAfterChanges}
                                />
                            }/>

                            <Route path="/manage/users" element={
                                <RequireAuth loginPath="/login" fallbackPath="/home">
                                    {checkIfUserHasPermission('user') && (
                                        <ManageUsers/>
                                    )}
                                </RequireAuth>
                            }/>

                            <Route path="/manage/images" element={
                                <RequireAuth loginPath="/login" fallbackPath="/home">
                                    {checkIfUserHasPermission('object') && (
                                        <ManageImages/>
                                    )}
                                </RequireAuth>
                            }/>

                            <Route path="/manage/categories" element={
                                <RequireAuth loginPath="/login" fallbackPath="/home">
                                    {checkIfUserHasPermission('category') && (
                                        <ManageCategories reloadAfterChanges={reloadAfterChanges}/>
                                    )}
                                </RequireAuth>
                            }/>

                            <Route path="/manage/comments" element={
                                <RequireAuth loginPath="/login" fallbackPath="/home">
                                    {checkIfUserHasPermission('comment') && (
                                        <ManageComments/>
                                    )}
                                </RequireAuth>
                            }/>

                            <Route path="/manage/groups" element={
                                <RequireAuth loginPath="/login" fallbackPath="/home">
                                    {checkIfUserHasPermission('group') && (
                                        <ManageGroups/>
                                    )}
                                </RequireAuth>
                            }/>

                            <Route path="/edit/profile" element={
                                <RequireAuth loginPath="/login" fallbackPath="/home">
                                    <EditProfile scrollToTop={scrollToTop}/>
                                </RequireAuth>
                            }/>

                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/comments" element={
                                <Comments
                                    currentUserData={currentUserData}
                                    reloadAfterChanges={reloadAfterChanges}
                                />
                            }/>
                            <Route path="/about-me" element={<AboutMe/>}/>
                            <Route path="/impressum" element={<Impressum/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route path="/forgotten-password" element={<ForgottenPassword/>}/>
                            <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                            <Route path="/activate-account/:token" element={<ActivateAccount/>}/>
                        </Routes>

                        {isScrollButtonVisible && (
                            <button onClick={scrollToTop} className="scrollToTopButton">
                                <FontAwesomeIcon icon={faArrowUp}/>
                            </button>
                        )}

                        <div className={`appFooter ${windowWidth < thresholdWidth ? 'is-responsive' : ''}`}>
                            <Link className="appFooterLink" to={'/'}>
                                {'Seelenwesen.de'}
                            </Link>

                            <Link className="appFooterLink" to={'/impressum'}>
                                {'Impressum'}
                            </Link>

                            <Link className="appFooterLink" to={'/privacy-policy'}>
                                {'Datenschutz'}
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </UserContext.Provider>
    );
}

export default App;
