import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useLocalStorage } from "usehooks-ts";
import ManageGroupTable from "../Tables/ManagedTables/ManageGroupTable";
import CreateGroup from "../../Element/Groups/CreateGroup";

export default function ManageGroups() {
    const [isUser] = useLocalStorage("isUser", "");
    const [isToken] = useLocalStorage("isToken", "");
    const [isGroups, setGroups] = useState([]);
    const [isUsers, setUsers] = useState([]);
    const [isLoadingGroups, setLoadingGroups] = useState(true);
    const [isLoadingUsers, setLoadingUsers] = useState(true);
    const [isChanged, setChanged] = useState(false);


    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //


    useEffect(() => {
        const fetchData = async () => {
            if (isLoadingGroups || isChanged) {
                try {
                    const response = await axios.get("/mGroups.php", config);

                    // Wenn die response.data bereits ein Objekt ist, wird es direkt verwendet
                    const groupsData = typeof response.data === 'object' ? response.data : JSON.parse(response.data);

                    setGroups(groupsData);
                    setLoadingGroups(false);
                    setChanged(false);
                } catch (error) {
                    console.error("Fehler beim Laden der Gruppe: ", error);
                    setLoadingGroups(false);
                    setChanged(false);
                }
            }
        };

        fetchData();
    }, [isLoadingGroups, isChanged]);

    useEffect(() => {
        const fetchData = async () => {
            if (isLoadingUsers || isChanged) {
                try {
                    const responseUsers = await axios.get("/mUsers.php", config);

                    setUsers(responseUsers.data);
                    setLoadingUsers(false);
                    setChanged(false);
                } catch (error) {
                    console.error("Fehler beim Laden der Gruppe: ", error);
                    setLoadingUsers(false);
                    setChanged(false);
                }
            }
        };

        fetchData();
    }, [isLoadingUsers, isChanged]);

    const reloadAfterChanges = () => {
        setChanged(true);
        setLoadingGroups(true);
        setLoadingUsers(true);
    };

    return (
        <>
            <div className="contentWrapper">
                <div className="normalForm">
                    <div className="loginFormContent">
                        <h1 className="loginTitle">Gruppen verwalten</h1>

                        <div className="formContainer">
                            <CreateGroup
                                reloadAfterChanges={reloadAfterChanges}
                            />

                            {isGroups.length > 0 && !isChanged && !isLoadingGroups && !isLoadingUsers ? (
                                <ManageGroupTable
                                    isGroups={isGroups}
                                    type={"ManageGroupTable"}
                                    isUsers={isUsers}
                                    reloadAfterChanges={reloadAfterChanges}
                                />
                            ) : (
                                <div className="messageWrapper">
                                    <div className="infoMessage">Es wurden keine Gruppen gefunden</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
