import React, {useState} from 'react';
import axios from 'axios';
import {useCookies} from "react-cookie";
import {useLocalStorage} from "usehooks-ts";

const CreateCategory = ({ fetchAfterChanges }) => {
    const [isUser] = useLocalStorage('isUser', '');
    const [isToken] = useLocalStorage('isToken', '');
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResponse, setResponse] = useState({})

    const handleResetInputs = () => {
        setCategoryName("")
        setCategoryDescription("")
    }

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div className="innerContentTitle">
                        <h2 className="responseMessage_success">{isResponse.message ? isResponse.message : ''}</h2>
                    </div>
                )
            }

            if (isResponse.message.length < 1) {
                return <div></div>
            } else {
                return (
                    <div className="innerContentTitle">
                        <h2 className="responseMessage_error">{isResponse ? isResponse.message : ''}</h2>
                    </div>
                )
            }
        } else {
            return (
                <div className="innerContentTitle">
                    <h2>Kategorie erstellen</h2>
                </div>
            )
        }
    }

    const getCurrentTimestamp = () => {
        const currentTimestampInMillis = Date.now();
        return Math.floor(currentTimestampInMillis / 1000);
    };

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`}};
    // --------------------------------- //

    const handleCreateCategory = async () => {
        try {
            setIsLoading(true);

            const currentTimestamp = getCurrentTimestamp();

            const response = await axios.post(`/mCategories.php`, {
                name: categoryName,
                description: categoryDescription,
                creation_date: currentTimestamp,
            }, config);

            if (response){
                setResponse(response.data)

                setTimeout(function(){
                    if (response.data.status === 1) {
                        handleResetInputs();
                        fetchAfterChanges()
                        // window.location.reload();
                    }
                    setResponse({})
                }, 1500)

            }

        } catch (error) {
            console.error('Fehler beim Erstellen der Kategorie:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="innerContentWrapper">

            {formatResponseMessage()}

            <div className="innerEditWrapper">
                <div className="innerEntry">
                    <div className="entryLabel">Name:</div>
                    <input
                        type="text"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                    />
                </div>

                <div className="innerEntry">
                    <div className="entryLabel">Beschreibung:</div>
                    <input
                        type="text"
                        value={categoryDescription}
                        onChange={(e) => setCategoryDescription(e.target.value)}
                    />
                </div>
            </div>

            <div>
                <button className="appButtonSave" onClick={handleCreateCategory} disabled={isLoading}>
                    {isLoading ? 'Erstelle Kategorie...' : 'Kategorie erstellen'}
                </button>
            </div>
        </div>
    );
};

export default CreateCategory;