// useSortableTable.js
import { useState, useEffect } from "react";

function getDefaultSorting(defaultTableData, columns) {
  const sorted = [...defaultTableData].sort((a, b) => {
    const filterColumn = columns.filter((column) => column.sortbyOrder);

    // Merge all array objects into a single object and extract accessor and sortbyOrder keys
    let { accessor = "id", sortbyOrder = "asc" } = Object.assign(
        {},
        ...filterColumn
    );

    if (a[accessor] === null) return 1;
    if (b[accessor] === null) return -1;
    if (a[accessor] === null && b[accessor] === null) return 0;

    const ascending = a[accessor]
        .toString()
        .localeCompare(b[accessor].toString(), "en", {
          numeric: true,
        });

    return sortbyOrder === "asc" ? ascending : -ascending;
  });
  return sorted;
}

export const useSortableTable = (data, columns) => {
  const [tableData, setTableData] = useState(getDefaultSorting(data, columns));

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
            a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };


  function isObjectKeyExists(array, keyToCheck) {
    return array.some(obj => obj.accessor === keyToCheck);
  }

  useEffect(() => {
    const storedSortingOrder = localStorage.getItem("sortingOrder");

    if (storedSortingOrder) {
      const [sortField, sortOrder] = storedSortingOrder.split(",");
      const sortKeyExists = isObjectKeyExists(columns, sortField);

      if (sortKeyExists) {
        handleSorting(sortField, sortOrder);
      } else {
        const defaultSortColumn = columns.find(column => column.accessor === sortField && column.defaultSort);

        if (defaultSortColumn) {
          handleSorting(sortField, defaultSortColumn.defaultSort);
        }
      }
    } else {
      // If no sorting order is stored in localStorage, apply default sorting
      const defaultSortColumn = columns.find(column => column.defaultSort);

      if (defaultSortColumn) {
        handleSorting(defaultSortColumn.accessor, defaultSortColumn.defaultSort);
      }
    }
  }, []);

  // useEffect(() => {
  //   const storedSortingOrder = localStorage.getItem("sortingOrder");
  //
  //   if (storedSortingOrder) {
  //     const [sortField, sortOrder] = storedSortingOrder.split(",");
  //     const sortKeyExists = isObjectKeyExists(columns, sortField);
  //
  //     if (sortKeyExists) {
  //       handleSorting(sortField, sortOrder);
  //     }
  //   }
  // }, []);


  return [tableData, handleSorting];
};
