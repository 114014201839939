import Table from "../Default/Table";

const columns = [
    { label: "ID", accessor: "id", sortable: true },
    { label: "Name", accessor: "name", sortable: true },
    { label: "Berechtigungen", accessor: "permissions", sortable: false },
    { label: "Aktionen", accessor: "actions" }
];

const ManageGroupTable = ({ isGroups, type, isUsers, reloadAfterChanges }) => {
    return (
        <>
            <Table
                caption=""
                data={isGroups}
                type={type}
                columns={columns}
                secondData={isUsers}
                itemsPerPage={10}
                reloadAfterChanges={reloadAfterChanges}
            />
        </>
    );
};

export default ManageGroupTable;
