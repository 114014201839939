import React, {useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../Login/Login.css';

export default function ResetPassword() {
    const navigate = useNavigate();
    const [isResponse, setResponse] = useState({})
    const { token } = useParams();

    const [inputs, setInputs] = useState(
        {
            'password': '',
            'confirmPassword': '',
            'urlToken': token
        }
    );
    const [isVisible, setVisible] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputs.password !== "" && inputs.confirmPassword !== "") {
            if (inputs.password !== inputs.confirmPassword) {
                setResponse({message: "Passwörter stimmen nicht überein!"})
            } else {
                setVisible(false)

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ password: inputs.password, urlToken: inputs.urlToken })
                };

                // fetch('http://localhost:80/react/api/resetpassword.php', requestOptions)
                fetch('https://api.seelenwesen.de/resetpassword.php', requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        setResponse(data)
                        if(data.status === 1) {
                            setTimeout(function(){
                                navigate("/login")
                            }, 1500)
                        }

                    }).catch((err) => {
                    console.log(err);
                })
            }

        } else {
            console.log('Bitte fülle alle Felder aus!')
        }
    }

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div
                        className="responseMessage_success"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }

            if (isResponse.message.length < 1) {
                return <div></div>
            } else {
                return (
                    <div
                        className="responseMessage_error"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }
        }
    }

    return (
        <>
            <div className="contentWrapper">
                <form className="loginForm" onSubmit={handleSubmit}>

                    <div className="loginFormContent">
                        <h1 className="loginTitle">Passwort ändern</h1>

                        {formatResponseMessage()}

                        <div className="inputWrapper">
                            <div style={{textAlign: 'center', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                Bitte gib ein neues Passwort für deinen Account ein.
                            </div>
                        </div>

                        <div className="inputWrapper">
                            <div className="inputEntry">
                                <label className="formLabel">Neues Passwort</label>
                                <input className="inputEntryPassword" name="password"
                                       type={isVisible ? 'text' : 'password'} value={inputs.password}
                                       onChange={handleChange} required/>
                            </div>

                            <div className="inputEntry">
                                <label className="formLabel">Neues Passwort bestätigen</label>
                                <input className="" name="confirmPassword" type={isVisible ? 'text' : 'password'}
                                       value={inputs.confirmPassword}
                                       onChange={handleChange} required/>
                            </div>
                        </div>


                        <div className="formButtonWrapper">

                            <div className="submitButtonWrapper">
                                <button className="submitButton" type="submit">Neues Passwort speichern</button>
                                <FontAwesomeIcon
                                    onClick={() => setVisible(!isVisible)}
                                    className="infoIconShowPassword"
                                    icon={isVisible ? faEye : faEyeSlash}
                                />
                            </div>

                            <div className="formButtonRegisterWrapper">
                                <Link className="registerLink" to={'../login'}>Zurück zum Login</Link>
                            </div>
                        </div>
                    </div>

                </form>
            </div>

        </>
    )
}