import React, {useEffect, useState} from "react";
import {useLocalStorage} from "usehooks-ts";
import {useCookies} from "react-cookie";
import axios from "axios";
import ManageCommentTable from "../Tables/ManagedTables/ManageCommentTable";

export default function ManageComments() {
    const [isUser] = useLocalStorage('isUser', '')
    const [isToken] = useLocalStorage('isToken', '')
    const [isComments, setComments] = useState([])
    const [isLoading, setLoading] = useState(true)

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`}};
    // --------------------------------- //

    useEffect(() => {
        const fetchData = async () => {
            if (isLoading) {
                try {
                    const response = await axios.get('/mComments.php', config);
                    setComments(response.data);
                    setLoading(false);

                } catch (error) {
                    // Handle error, if needed
                    console.error('Fehler beim Laden der Kategorien: ', error);
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [isLoading]);

    return (
        <>
            <div className="contentWrapper">
                <div className="normalForm">
                    <div className="loginFormContent">
                        <h1 className="loginTitle">
                            Kommentare verwalten
                        </h1>

                        <div className="formContainer">

                            {config && isComments.length > 0 && !isLoading ? (
                                <ManageCommentTable
                                    data={isComments}
                                    type={"ManageCommentsTable"}
                                    apiConfig={config}
                                />
                            ) : (
                                <div className="messageWrapper">
                                    <div className="infoMessage">
                                        Es wurden keine Kommentare gefunden
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}
