import React, { useContext, useEffect, useState } from "react";
import { useModal } from "../../Element/Modal/ModalProvider";
import EditObjectModal from "../../Element/Modal/EditObjectModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../App";

export default function PreviewDescription({ data, categories }) {
    const { openModal } = useModal();
    const user = useContext(UserContext);
    const [currentData, setCurrentData] = useState(data); // Zustand für die Daten

    // useEffect, um die Daten zu aktualisieren, wenn sich 'data' ändert
    useEffect(() => {
        setCurrentData(data);
    }, [data]);

    const checkIfUserHasPermission = (permission) =>
        user?.group_id === 1
            ? true
            : (user?.[`perm_${permission}`] === 1 && user.status === "active") || false;

    const openEditModal = (data) => {
        openModal(
            <EditObjectModal
                dataId={currentData.id}
                initialData={currentData}
                secondData={categories}
            />
        );
    };

    return (
        <>
            <div className="previewBottomTitleAndDescriptionWrapper">
                <div className="userDescriptionWrapper">
                    <div className="userDescriptionTitleWrapper">
                        <h2 className="previewBottomTitle">{currentData.name || 'Kein Titel'}</h2>
                        <div className="previewBottomDescription">{currentData.description || 'Keine Beschreibung'}</div>
                    </div>
                    {checkIfUserHasPermission('object') && (
                        <div className="previewBottomAdminButtons">
                            <button
                                style={{ borderRadius: '0.5rem', fontSize: '1rem', padding: '0' }}
                                className="appButtonCancel"
                                onClick={() => {
                                    openEditModal(currentData);
                                }}
                            >
                                {<FontAwesomeIcon icon={faGear} />}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}