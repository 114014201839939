import React, {useEffect, useRef, useState} from "react";
import {useModal} from "./ModalProvider";
import axios from "axios";
import {useCookies} from "react-cookie";
import {useLocalStorage} from "usehooks-ts";
import formatName from "../Utilities/NameFormatter";
import CustomSelect from "../Custom/CustomSelect";
import Tooltip from "../Tooltip/Tooltip";

const EditGroupModal = ({ tableData, initialData, secondData, reloadAfterChanges }) => {
    const [isUser] = useLocalStorage('isUser', '');
    const [isToken] = useLocalStorage('isToken', '');
    const [isResponse, setResponse] = useState({});
    const [responseTimer, setResponseTimer] = useState(null);
    const [editedGroupData, setEditedGroupData] = useState({ ...initialData });
    const [deleteButtonText, setDeleteButtonText] = useState("Löschen");
    const deleteButtonTimerRef = useRef(null);

    const { closeModal } = useModal();

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(['_auth']);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setEditedGroupData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
            };

            return updatedData;
        });
    };

    const handleSave = async () => {
        try {
            const response = await axios.put(`/mGroups.php/`, editedGroupData, config);

            if (response.data.status === 1) {
                setTimeout(() => {
                    window.location.reload();
                    closeModal();
                }, 1500);
            }

            setResponse(response.data);
            setResponseTimer(true);
        } catch (error) {
            console.error("Fehler beim Updaten der Gruppe:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axios.delete("/mGroups.php", {
                data: { id: editedGroupData.id },
                headers: config.headers,
            });

            setResponse(response.data);
            setResponseTimer(true);

            if (response.data.status === 1) {
                setTimeout(() => {
                    window.location.reload();
                    closeModal();
                }, 1500);
            }
        } catch (error) {
            console.error("Fehler beim Löschen der Gruppe:", error);
        }
    };

    const handleDeleteButtonClick = () => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        setDeleteButtonText("Doppelklicken!");

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("Löschen");
        }, 2000); // Reset the text after 2 seconds
    };

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div className="responseMessage_success">
                        {isResponse.message ? isResponse.message : ""}
                    </div>
                );
            }

            if (isResponse.message.length < 1) {
                return <div></div>;
            } else {
                return (
                    <div className="responseMessage_error">
                        {isResponse ? isResponse.message : ""}
                    </div>
                );
            }
        }
    };

    useEffect(() => {
        if (responseTimer) {
            const timerId = setTimeout(() => {
                setResponseTimer(null);
                setResponse({});
            }, 1500);

            // Clear the timer if the component unmounts
            return () => clearTimeout(timerId);
        }
    }, [responseTimer]);

    const filterUsersByGroupId = () => {
        return secondData.filter(user => user.group_id === editedGroupData.id);
    };

    const getGroupDataForUser = (userInGroup) => {
        return tableData.filter(group => group.id === userInGroup.group_id)
    }

    const handleUserGroupSelectChange = async (name, value, currentUser) => {
        const updatedUser = {
            ...currentUser,
            [name]: value,
        };

        try {
            const response = await axios.put(`/mUsers.php/`, updatedUser, config);
            if (response.data.status === 1) {
                reloadAfterChanges()
                setResponse(prevResponse => ({
                    ...prevResponse,
                    message:  response.data.message,
                    status: 1
                }));
                setResponseTimer(true)
            } else {
                setResponse(prevResponse => ({
                    ...prevResponse,
                    message:  "Gruppe des Benutzers konnte nicht verändert werden",
                    status: 0
                }));
                setResponseTimer(true)
            }
        } catch (error) {
            console.error("Fehler beim Updaten des Benutzers:", error);
        }
    };

    return (
        <div className="modalFormContent">
            <div className="modalTitleWrapper">
                {formatResponseMessage()}

                {responseTimer === null && (
                    <>
                        <h2 className="modalTitleLeft">Gruppe bearbeiten:</h2>
                        <div className="modalTitleRight">
                            {formatName(editedGroupData.name)} ({editedGroupData.id})
                        </div>
                    </>
                )}

            </div>

            <div className="editModalContentWrapper">
                <div className="editModalContent">

                    <div className="editModalWrapper">
                        <div className="editModalEntry">
                            <div className="entryLabel">Name:</div>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={editedGroupData.name || ""}
                                onChange={handleChange}
                            />
                        </div>


                        <div className="innerEditWrapper">
                            <div className="entryLabel">Admin-Berechtigungen:</div>
                            <div className="innerEntry">
                                <div className="entryCheckBoxWrapper">
                                    <input
                                        type="checkbox"
                                        id="isPermObject"
                                        name="perm_object"
                                        checked={editedGroupData.perm_object === 1}
                                        onChange={handleChange}
                                    />
                                    <div className="entryLabel">Bilder</div>
                                </div>

                                <div className="entryCheckBoxWrapper">
                                    <input
                                        type="checkbox"
                                        id="isPermCategory"
                                        name="perm_category"
                                        checked={editedGroupData.perm_category === 1}
                                        onChange={handleChange}
                                    />
                                    <div className="entryLabel">Kategorien</div>
                                </div>

                                <div className="entryCheckBoxWrapper">
                                    <Tooltip color={'rgb(209, 84, 84)'} tooltipText="Gehe vorsichtig mit dieser Berechtigung um!">
                                        <input
                                            className="checkboxWarning"
                                            type="checkbox"
                                            id="isPermUser"
                                            name="perm_user"
                                            checked={editedGroupData.perm_user === 1}
                                            onChange={handleChange}
                                        />
                                    </Tooltip>
                                    <div className="entryLabel">Benutzer</div>
                                </div>

                                <div className="entryCheckBoxWrapper">
                                    <Tooltip color={'rgb(209, 84, 84)'} tooltipText="Gehe vorsichtig mit dieser Berechtigung um!">
                                        <input
                                            className="checkboxWarning"
                                            type="checkbox"
                                            id="isPermGroup"
                                            name="perm_group"
                                            checked={editedGroupData.perm_group === 1}
                                            onChange={handleChange}
                                        />
                                    </Tooltip>
                                    <div className="entryLabel">Gruppen</div>
                                </div>

                                <div className="entryCheckBoxWrapper">
                                    <input
                                        type="checkbox"
                                        id="isPermComment"
                                        name="perm_comment"
                                        checked={editedGroupData.perm_comment === 1}
                                        onChange={handleChange}
                                    />
                                    <div className="entryLabel">Kommentare</div>
                                </div>
                            </div>
                        </div>


                        <div className="innerEditWrapper">
                            <div className="entryLabel">Benutzer in Gruppe:</div>
                            <div className="innerEntryTable">

                                {filterUsersByGroupId().map((userInGroup, index) => {
                                    return (
                                    <div key={userInGroup.username + index} className="innerContent">
                                        <div className="entryLabel" style={{color: '#d1d1d1'}}>{formatName(userInGroup.username)}</div>
                                        <CustomSelect
                                            optionArray={tableData}
                                            onSelect={(selectedOption) =>
                                                handleUserGroupSelectChange("group_id", selectedOption, userInGroup)
                                            }
                                            defaultValue={getGroupDataForUser(userInGroup)[0].name}
                                            optionKey="status"
                                        />
                                    </div>
                                    )
                                })}

                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <div className="editModalButtons">
                <button className="appButtonSave" onClick={handleSave}>
                    Speichern
                </button>
                <button
                    className="appButtonDelete"
                    onDoubleClick={handleDelete}
                    onClick={handleDeleteButtonClick}
                >
                {deleteButtonText}
                </button>
            </div>
        </div>
    );
};

export default EditGroupModal;
