import React, { createContext, useContext, useState } from 'react';
import Modal from "./Modal";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [toggleKeysLocked, setToggleKeysLocked] = useState(false)

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
        setToggleKeysLocked(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
        setToggleKeysLocked(false);
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal, isModalOpen, toggleKeysLocked }}>
            {children}
            {isModalOpen &&
                <Modal content={modalContent} onClose={closeModal}/>
            }
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};