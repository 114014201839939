import React, {useState, useContext, useEffect, useRef} from 'react';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useLocalStorage } from "usehooks-ts";
import {UserContext} from "../../App";
import formatName from "../../Element/Utilities/NameFormatter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faRemove} from "@fortawesome/free-solid-svg-icons";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import Tooltip from "../../Element/Tooltip/Tooltip";

const EditProfile = ({ scrollToTop }) => {
    const user = useContext(UserContext);
    const [isUser, setUser] = useLocalStorage('isUser', '');
    const [isToken, setToken] = useLocalStorage('isToken', '');
    const [isLoading, setIsLoading] = useState(false);
    const [isResponse, setResponse] = useState({});
    const [isAvatars, setAvatars] = useState({})
    const [isSelectedAvatar, setSelectedAvatar] = useState(0)
    const [userData, setUserData] = useState({
        username: user.username || '',
        email: user.email || '',
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        password: '',
        currentPassword: '',
        avatar_path: user.avatar_path !== undefined ? user.avatar_path : null
    });

    useEffect(() => {
        if (user.username) {
            setUserData(prevUserData => ({
                ...prevUserData,
                username: user.username,
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname
            }));
        }
    }, [user]);

    const handleResetInputs = () => {
        setUserData({
            ...userData,
            password: '',
            currentPassword: ''
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Wenn das geklickte Element ein Avatar-Bild ist
        if (e.target.tagName === 'IMG' && name === 'avatar_path') {
            // Aktualisiere den userData mit dem Pfad des geklickten Bildes
            setUserData(prevUserData => ({
                ...prevUserData,
                [name]: value
            }));
        } else {
            // Ansonsten aktualisiere den userData wie bisher
            setUserData(prevUserData => ({
                ...prevUserData,
                [name]: value
            }));
        }
    };

    const scrollRef = useRef(null);

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`
        }
    };
    // --------------------------------- //

    const handleUpdateProfile = async () => {
        try {
            setIsLoading(true);
            const response = await axios.put('/editProfile.php', userData, config);

            if (response) {
                setResponse(response.data);
                scrollToTop()

                setTimeout(function () {
                    if (response.data.status === 1) {
                        handleResetInputs();
                        window.location.reload();
                    }
                    setResponse({});
                }, 1500);
            }
        } catch (error) {
            console.error('Fehler beim Aktualisieren des Profils:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        axios
            .get(`/userAvatars.php/`)
            .then((response) => {
                setAvatars(response.data);
            })
            .catch((error) => {
                console.error("Fehler beim Laden der Avatare: ", error);
            });
    }, []);


    useEffect(() => {
        if (user && user.avatar_path && Object.keys(isAvatars).length > 0) {
            const avatarArray = Object.values(isAvatars);
            const selectedAvatarIndex = avatarArray.findIndex(avatar => avatar.avatar_path === user.avatar_path);
            setSelectedAvatar(selectedAvatarIndex !== -1 ? avatarArray[selectedAvatarIndex].id : 0);

            // Scrollt zum ausgewählten Avatar
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
    }, [user, isAvatars]);

    const deleteButtonTimerRef = useRef(null);
    const [deleteButtonText, setDeleteButtonText] = useState("Account löschen");
    const [responseTimer, setResponseTimer] = useState(null);

    const handleDeleteButtonClick = () => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        setDeleteButtonText("Doppelklicken!");

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("Account löschen");
        }, 2000); // Setze den Text nach 2 Sekunden zurück
    };

    const signOut = useSignOut();
    const signOutFunction = () => signOut()

    const handleLogout = () => {
        if (isUser !== "" && isToken !== "") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: isUser,
                    loginToken: isToken
                })
            };

            // fetch('http://localhost:80/react/api/logout.php', requestOptions)
            fetch('https://api.seelenwesen.de/logout.php', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    signOutFunction();

                    setTimeout(function () {
                        setUser('');
                        setToken('');
                        window.location.reload();
                    }, 1500);

                })
                .catch((err) => {});
        }
    }

    const handleDelete = async () => {
        try {
            const response = await axios.delete("/editProfile.php", {
                data: { id: user.id },
                headers: config.headers,
            });

            scrollToTop()
            setResponse(response.data);
            setResponseTimer(true);

            if (response.data.status === 1) {
                setTimeout(() => {
                    handleLogout()
                }, 1500);
            }
        } catch (error) {
            console.error("Fehler beim Löschen der Gruppe:", error);
        }
    };

    const [isParallax, setParallax] = useLocalStorage('isParallax', 'true');
    const [toggleValue, setToggleValue] = useState(isParallax === 'true' || false);

    useEffect(() => {
        // Setze den Wert des Toggle-Schalters basierend auf dem Wert aus dem localStorage
        setToggleValue(isParallax === 'true');
    }, [isParallax]);

    const handleToggleChange = () => {
        const newValue = !toggleValue; // Kehre den aktuellen Wert um
        setToggleValue(newValue); // Aktualisiere den Wert des Toggle-Schalters im lokalen Zustand
        setParallax(newValue.toString()); // Aktualisiere den Wert im localStorage
    };

    return (
        <>
            <div className="contentWrapper">
                <div className="normalForm" style={{maxWidth: '40rem', width: '100%'}}>
                    <div className="loginFormContent">

                        {isResponse && isResponse.message ? (
                            <div className="loginTitle">
                                <h1 className={isResponse.status === 1 ? "responseMessage_success" : "responseMessage_error"}>
                                    {isResponse.message}
                                </h1>
                            </div>
                        ) : (
                            <h1 className="loginTitle">Mein Profil</h1>
                        )}

                        <div className="formContainer">

                            <h2 className="innerContentTitle">Avatar auswählen</h2>
                            <div className="innerAvatarWrapper">
                                {isAvatars.length > 5 && (
                                    <>
                                        <div
                                            ref={scrollRef}
                                            style={{
                                                minWidth: '6rem',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderColor: '#997878'
                                            }}
                                            className={`avatarImg${isSelectedAvatar === 0 ? '-isSelected' : ''}`}
                                            onClick={() => {
                                                setSelectedAvatar(0);
                                                handleInputChange({target: {name: 'avatar_path', value: 0}});
                                            }}
                                        >
                                            <FontAwesomeIcon style={{fontSize: '3rem', color: '#997878'}}
                                                             icon={faRemove}/>
                                        </div>

                                        {isAvatars.map((avatar, index) => {
                                            return (
                                                <img
                                                    ref={isSelectedAvatar === avatar.id ? scrollRef : null}
                                                    className={`avatarImg${isSelectedAvatar === avatar.id ? '-isSelected' : ''}`}
                                                    key={`${avatar.id} - ${index}`}
                                                    src={avatar.avatar_path}
                                                    alt={`${avatar.id}`}
                                                    onClick={() => {
                                                        setSelectedAvatar(avatar.id);
                                                        handleInputChange({
                                                            target: {
                                                                name: 'avatar_path',
                                                                value: avatar.avatar_path
                                                            }
                                                        });
                                                    }}
                                                />
                                            )
                                        })}
                                    </>
                                )}
                            </div>


                            <h2 className="innerContentTitle">Persönliche Daten</h2>
                            <div className="innerEditWrapper" style={{background: '#0000001a'}}>
                                <div className="innerEntry">
                                    <div className="entryLabel">Benutzername:</div>
                                    <input
                                        disabled
                                        type="text"
                                        name="username"
                                        value={formatName(userData.username)}
                                    />
                                </div>
                                <div className="innerEntry">
                                    <div className="entryLabel">Gruppe:</div>
                                    <input
                                        disabled
                                        type="text"
                                        name="group"
                                        value={formatName(user.group_name)}
                                    />
                                </div>
                                <div className="innerEntry">
                                    <div className="entryLabel">Status:</div>
                                    <input
                                        disabled
                                        type="text"
                                        name="status"
                                        value={
                                            user.status === 'active' ?
                                                'Aktiviert' :
                                                user.status === 'registered' ?
                                                    'Registriert' :
                                                    user.status === 'disabled' ?
                                                        'Deaktiviert' :
                                                        ''
                                        }
                                    />
                                </div>
                                <div className="innerEntry">
                                    <div className="entryLabel">E-Mail:</div>
                                    <input
                                        type="email"
                                        name="email"
                                        value={userData.email || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="innerEntry">
                                    <div className="entryLabel">Vorname:</div>
                                    <input
                                        type="text"
                                        name="firstname"
                                        value={userData.firstname || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="innerEntry">
                                    <div className="entryLabel">Nachname:</div>
                                    <input
                                        type="text"
                                        name="lastname"
                                        value={userData.lastname || ''}
                                        onChange={e => handleInputChange(e)}
                                    />
                                </div>

                                <div className="innerEntry">
                                    <div className="entryLabel">
                                        {'Parallax aktiviert:'}
                                        <Tooltip tooltipText={'Schalte die beweglichen Bilder in der Galerie an oder aus'}>
                                            <FontAwesomeIcon className="entryLabelInfoIcon" icon={faCircleInfo} />
                                        </Tooltip>
                                    </div>
                                    <div className="innerEntryCheckbox" style={{width: '100%'}}>
                                        <input
                                            name="checkBoxParralax"
                                            type="checkbox"
                                            checked={toggleValue !== undefined ? toggleValue : false}
                                            onChange={handleToggleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <h2 className="innerContentTitle">Passwort ändern</h2>
                            <div className="innerEditWrapper" style={{background: '#0000001a'}}>
                                <div className="innerEntry">
                                    <div className="entryLabel">Neues Passwort:</div>
                                    <input
                                        type="password"
                                        name="password"
                                        value={userData.password}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="innerEntry">
                                    <div className="entryLabel">Aktuelles Passwort:</div>
                                    <input
                                        type="password"
                                        name="currentPassword"
                                        value={userData.currentPassword}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <h2 className="innerContentTitle">Account löschen</h2>
                            <div className="innerEditWrapper" style={{background: '#0000001a'}}>

                                <div className="innerEntry">
                                    <div className="entryLabel">Möchtest du deinen Account dauerhaft löschen?</div>
                                    <button
                                        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '1.5rem', maxWidth: '9rem'}}
                                        className="appButtonDelete"
                                        onClick={handleDeleteButtonClick}
                                        onDoubleClick={handleDelete}
                                    >
                                        {deleteButtonText}
                                    </button>
                                </div>

                            </div>

                            <div className="innerEntryButtons">
                                <button className="appButtonSave" style={{maxWidth: '10rem'}}
                                        onClick={handleUpdateProfile}
                                        disabled={isLoading}>
                                    {isLoading ? 'Profil aktualisieren...' : 'Profil aktualisieren'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>

    );
};

export default EditProfile;
