import React, {useContext, useEffect, useState} from "react";
import ImageUploader from "../../Element/Uploader/ImageUploader";
import {useLocalStorage} from "usehooks-ts";
import {useCookies} from "react-cookie";
import axios from "axios";
import ManageObjectTable from "../Tables/ManagedTables/ManageObjectTable";
import {Link} from "react-router-dom";
import {UserContext} from "../../App";

export default function ManageImages() {
    const [isUser] = useLocalStorage('isUser', '')
    const [isToken] = useLocalStorage('isToken', '')
    const [isObjects, setObjects] = useState([])
    const [isCategories, setCategories] = useState([])
    const [isLoading, setLoading] = useState(true)
    const user = useContext(UserContext);

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`}};
    // --------------------------------- //

    async function fetchData() {
        axios.get('/mObjects.php', config).then(function(response) {
            setObjects(response.data);
            setLoading(false)
        })
    }

    useEffect(() => {
        if (isLoading) {
            fetchData().then(r => {setLoading(false)});
        }
    },);


    useEffect(() => {
        if (user.perm_object === 1) {
            axios
                .get(`/userCategories.php`)
                .then((response) => {
                    setCategories(response.data);
                })
                .catch((error) => {
                    console.error('Fehler beim Laden der Kategorien:', error);
                });
        } else {
            axios
                .get(`/mCategories.php`, config)
                .then((response) => {
                    setCategories(response.data);
                })
                .catch((error) => {
                    console.error('Fehler beim Laden der Kategorien:', error);
                });
        }

    },[]);

    return (
        <>
            <div className="contentWrapper">
                <div className="normalForm">
                    <div className="loginFormContent">
                        <h1 className="loginTitle">
                            Bilder verwalten
                        </h1>
                        <div className="formContainer">

                            {isCategories.length > 0 ? (
                                <ImageUploader categories={isCategories} />
                            ) : (
                                <div className="messageWrapper">
                                    <div className="warningMessage">
                                        Du musst zuerst eine Kategorie erstellen
                                    </div>
                                    <Link to={'/manage/categories'}>
                                        <button className="appButtonNormal" style={{marginTop: '0.5rem'}}>Kategorie erstellen</button>
                                    </Link>
                                </div>
                            )}

                            {isObjects.length > 0 ? (
                                <ManageObjectTable
                                    data={isObjects}
                                    secondData={isCategories}
                                    type={"ManageObjectTable"}
                                    apiConfig={config}
                                />
                            ) : (
                                <div className="messageWrapper">
                                    <div className="infoMessage">
                                        Es wurden keine Bilder gefunden
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
