import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import axios from "axios";
import AuthProvider from "react-auth-kit";
import createStore from 'react-auth-kit/createStore';
import {ModalProvider} from "./Element/Modal/ModalProvider";
import CookieAcceptance from "./Element/Cookie/CookieAcceptance";

const root = ReactDOM.createRoot(document.getElementById('root'));
axios.defaults.baseURL = 'https://api.seelenwesen.de';
// axios.defaults.baseURL = 'http://localhost:80/react/api';

const store = createStore({
    authName:'_auth',
    authType:'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
});

root.render(
    <React.StrictMode>
        <AuthProvider store={store}>
            <BrowserRouter>
                <ModalProvider>
                    <CookieAcceptance />
                    <App />
                </ModalProvider>
            </BrowserRouter>
        </AuthProvider>
    </React.StrictMode>
);

reportWebVitals();
