import React from 'react';
import './Modal.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";

const Modal = ({ content, onClose }) => {

    const handleClickOutside = (event) => {
        if (event.target.classList.contains('modalWrapper')) {
            onClose();
        }
    };

    return (
        <div className="modalWrapper" onClick={handleClickOutside}>
            <div className="modal">
                <div className="modalHeader">
                    <button className="modalButton" onClick={onClose}>
                        <FontAwesomeIcon className="modalIconClose" icon={faX}/>
                    </button>
                </div>

                <div className="modalContent">{content}</div>
            </div>
        </div>
    );
};

export default Modal;