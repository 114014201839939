import React, {useState} from 'react';
import axios from 'axios';
import './Uploader.css';
import { useLocalStorage } from 'usehooks-ts';
import { useCookies } from 'react-cookie';
import CustomSelect from '../Custom/CustomSelect';

const ImageUploader = ({ categories }) => {
    const [isUser] = useLocalStorage('isUser', '');
    const [isToken] = useLocalStorage('isToken', '');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [previewImages, setPreviewImages] = useState([]);
    const [isResponse, setResponse] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [maxFiles] = useState(35); // Maximum number of files
    const [totalFilesSize, setTotalFilesSize] = useState(0); // Total size of all selected files

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(['_auth']);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //

    const isDuplicateFileName = (fileName) => {
        return selectedFiles.some((file) => file.name === fileName);
    };

    const updateTotalFileSize = (files) => {
        const totalSize = files.reduce((total, file) => total + file.size, 0);
        setTotalFilesSize(totalSize / (1024 * 1024)); // Conversion to megabytes
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        const newFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Check for duplicate by file name
            if (!isDuplicateFileName(file.name)) {
                newFiles.push(file);
                setPreviewImages((prevImages) => [...prevImages, URL.createObjectURL(file)]);
            }
        }

        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);

        // Update the total size of the selected files
        updateTotalFileSize([...selectedFiles, ...newFiles]);
    };

    const handleRemove = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);

        const newPreviewImages = [...previewImages];
        newPreviewImages.splice(index, 1);
        setPreviewImages(newPreviewImages);

        // Update the total size when a file is removed
        updateTotalFileSize(newFiles);
    };

    const handleRemoveAll = () => {
        setSelectedFiles([]);
        setPreviewImages([]);
        setTotalFilesSize(0);
    };

    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);
    };

    const handleUpload = () => {
        if (selectedFiles.length > 0 && !isLoading && selectedCategory !== '') {
            setIsLoading(true);
            const formData = new FormData();

            selectedFiles.forEach((file, index) => {
                formData.append(`image${index + 1}`, file);
            });

            // Set categoryId to form
            formData.append('categoryId', selectedCategory);

            axios
                .post('/mObjects.php', formData, config)
                .then((response) => {
                    setResponse(response.data);
                    setTimeout(() => {
                        if (response.data.status === 1) {
                            handleRemoveAll();
                        }
                        setIsLoading(false);
                        setResponse({});
                        window.location.reload()

                    }, 1500);
                })
                .catch((error) => {
                    console.error('Fehler beim Hochladen der Bilder:', error);
                    setIsLoading(false);
                });
        }
    };

    const ImageContainer = ({ imageUrl, index }) => (
        <div className="uploadImageContainer">
            <img className="uploadPreviewImage" src={imageUrl} alt={`Preview ${index}`} />
        </div>
    );

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div
                        className="responseMessage_success"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem', marginBottom: '0.5rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }

            if (isResponse.message.length < 1) {
                return <div></div>
            } else {
                return (
                    <div
                        className="responseMessage_error"
                        style={{marginTop: '0.5rem', fontSize:'0.85rem', marginBottom: '0.5rem'}}
                    >
                        {isResponse ? isResponse.message : ''}
                    </div>
                )
            }
        }
    }

    const isResponseEmpty = Object.keys(isResponse).length === 0;

    return (
        <div className="uploadImages">
            <div className="innerContentWrapper">

                {isLoading && isResponseEmpty ? (
                    <div className="loginTitle">
                        <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h2 className="innerContentTitle">Neue Bilder hochladen</h2>
                    </div>
                )}

                {isResponse && formatResponseMessage()}

                {selectedFiles.length > 0 && (
                    <div>
                        <button
                            className="appButtonDelete"
                            onClick={handleRemoveAll}
                            disabled={isLoading}
                        >
                            {'Alle entfernen'}
                        </button>
                    </div>
                )}

                <div className="dataEntryWrapper">
                    <div className="dataInfoWrapper">
                        <span className="dataInfoTitle">Dateien:</span>
                        <span className="dataInfoValues">{selectedFiles.length} / {maxFiles}</span>
                    </div>
                    <div className="dataInfoWrapper">
                        <span className="dataInfoTitle">Größe:</span>
                        <span className="dataInfoValues">{totalFilesSize.toFixed(0)} MB / 120 MB</span>
                    </div>
                </div>

                <div className="uploadHeaderWrapper">
                    <CustomSelect
                        optionArray={categories}
                        onSelect={handleCategorySelect}
                        useLink={false}
                        sortKey="name"
                        order="asc"
                    />

                    <label className="labelAppButtonNormal" htmlFor="fileInput">
                        Wähle Dateien aus
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        onChange={handleFileChange}
                        multiple
                        className="customFileInput"
                    />
                </div>

                <div className="uploadPreviewWrapper">
                    {previewImages.map((imageUrl, index) => (
                        <div className="uploadPreviewEntry" key={index}>
                            <ImageContainer imageUrl={imageUrl} index={index} />
                            <button
                                className="appButtonCancel"
                                onClick={() => handleRemove(index)}
                                disabled={isLoading}
                            >
                                Entfernen
                            </button>
                        </div>
                    ))}
                </div>

                <button
                    className="appButtonSave"
                    onClick={handleUpload}
                    disabled={isLoading || selectedFiles.length === 0 || selectedCategory.length === 0}
                >
                    {'Bilder hochladen'}
                </button>
            </div>
        </div>
    );
};

export default ImageUploader;