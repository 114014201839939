import React, { useState, useRef, useEffect } from "react";
import './ImagePreview.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faClose,
} from "@fortawesome/free-solid-svg-icons";
import PreviewBottomContent from "../../components/PreviewBottom/PreviewBottomContent";
import { useModal } from "../Modal/ModalProvider";

const ImagePreview = ({ images, categories, selectedIndex, onClose, currentUser, reloadAfterChanges }) => {
    const [startX, setStartX] = useState(0);
    const [endX, setEndX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [endY, setEndY] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(selectedIndex);
    const imgRef = useRef(null);
    const modalContentRef = useRef(null);

    /* ToggleKeysLocked comes from modalProvider and stops key press functions for modal inputs */
    const { toggleKeysLocked } = useModal();

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        setEndX(e.touches[0].clientX);
        setEndY(e.touches[0].clientY);
    };

    const handleTouchEnd = () => {
        const deltaX = startX - endX;
        const deltaY = startY - endY;

        const thresholdHorizontal = 210; // Threshold for horizontal swipe
        const thresholdVertical = 250; // Threshold for vertical swipe

        if (Math.abs(deltaX) > thresholdHorizontal && Math.abs(deltaX) > Math.abs(deltaY)) {
            if (deltaX > thresholdHorizontal) {
                nextImage();
            } else if (deltaX < -thresholdHorizontal) {
                prevImage();
            }
        } else if (Math.abs(deltaY) > thresholdVertical && Math.abs(deltaY) > Math.abs(deltaX)) {
            // Separate logic for scrolling here
            // if the vertical shift is large enough and the image is not changed
        }

        setStartX(0);
        setEndX(0);
        setStartY(0);
        setEndY(0);
    };

    const handleKeyPress = (event) => {
        if (toggleKeysLocked === false) {
            if (event.key === "ArrowLeft") {
                prevImage();
            } else if (event.key === "ArrowRight") {
                nextImage();
            } else if (event.key === "Escape") {
                onClose();
            }
        }
    };


    useEffect(() => {
        if (toggleKeysLocked === false) {
            window.addEventListener("keydown", handleKeyPress);

            return () => {
                window.removeEventListener("keydown", handleKeyPress);
            };
        }
    }, [currentIndex, images]);

    return (
        <div className="modal-overlay">
            <div
                className="modal-content"
                ref={modalContentRef}
            >
                <div className="close-button" onClick={onClose}>
                    <div
                        className="imageWrapper"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <div className="imageCloseButton">
                            <FontAwesomeIcon icon={faClose} />
                        </div>

                        <img
                            className="modal-content-image"
                            src={images[currentIndex].image_path}
                            alt={`Preview ${currentIndex + 1}`}
                            ref={imgRef}
                        />
                    </div>
                </div>
                <div className="prev-arrow" onClick={prevImage}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </div>
                <div className="next-arrow" onClick={nextImage}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>

                <PreviewBottomContent
                    data={images[currentIndex]}
                    categories={categories}
                    currentUser={currentUser}
                    reloadAfterChanges={reloadAfterChanges}
                />

            </div>
        </div>
    );
};

export default ImagePreview;