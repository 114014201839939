import React from "react";
import PreviewComments from "./PreviewComments";
import PreviewDescription from "./PreviewDescription";

export default function PreviewBottomContent({ data, categories, currentUser, reloadAfterChanges }) {

    return(
        <>
            <div className="previewBottomWrapper">
                <PreviewDescription data={data} categories={categories} />
                <PreviewComments currentImageData={data} currentUser={currentUser} reloadAfterChanges={reloadAfterChanges} />
            </div>
        </>
    )
}