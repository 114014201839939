import React, { useState, useRef, useEffect } from "react";
import {Link, useLocation} from "react-router-dom";
import './CustomSelect.css';
import DataSorter from "../Utilities/DataSorter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";

const CustomSelect = ({ optionArray, onSelect, useLink, linkPath, sortKey, order, defaultValue, optionKey, centered }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultValue || 'Bitte auswählen');
    const selectRef = useRef(null);

    const sortedOptionArray = () => {
        if (sortKey && order) {
            return (
                DataSorter({ dataArray: optionArray, sortKey: sortKey, order: order })
            )
        }
        return (
            optionArray
        )
    };

    const sortedData = sortedOptionArray();

    const handleOptionClick = (option) => {
        setSelectedOption(option.name);
        setIsOpen(false);

        if (onSelect && !useLink) {
            const selectedKey = option[optionKey] || option.id;
            onSelect(selectedKey);
        }
    };

    const handleSelectClick = () => {
        setIsOpen(!isOpen);
    };

    const handleDocumentClick = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const location = useLocation();
    useEffect(() => {
        // Here we compare the current path with the options and set the selected option
        const currentOption = sortedData.find(option => {
            const path = useLink ? getLinkPath(option) : option.name;
            return location.pathname === path;
        });

        if (currentOption) {
            setSelectedOption(currentOption.name);
        }
    }, [location.pathname, sortedData, useLink]);

    const getClassNameForSelected = () => {
        if (isOpen && !centered) {
            return ('select-selected select-arrow-active')
        }

        if (isOpen && centered) {
            return ('select-selected-isCentered select-arrow-active')
        }

        if (centered) {
            return ('select-selected-isCentered')
        }

        return 'select-selected'
    }

    const getLinkPath = (option) => {
        if (option.id === 0) {
            return ('/')
        } else {
            return (`${linkPath}/${option.id}`)
        }
    }

    return (
        <div className="custom-select" ref={selectRef}>
            <div className={getClassNameForSelected()} onClick={handleSelectClick}>
                {selectedOption}
            </div>
            <div className={`select-items ${isOpen ? '' : 'select-hide'}`}>
                {sortedData.map((option, index) => {
                    const clickableElement = useLink ? (
                        <Link
                            key={`option ${index}`}
                            value={option.id}
                            to={getLinkPath(option)}
                        >
                            <div
                                className="select-option"
                                key={`option ${index}`}
                                value={option.id}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.id === 0 ? (
                                    <>
                                        <FontAwesomeIcon icon={faHome} />
                                        {' Startseite'}
                                    </>
                                ) : (
                                    option.name
                                )}
                            </div>
                        </Link>
                    ) : (
                        <div className="select-option" onClick={() => handleOptionClick(option)} value={option.id}>
                            {option.name}
                        </div>
                    );

                    return (
                        <div key={`option ${index}`} onClick={() => handleOptionClick(option)}>
                            {clickableElement}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CustomSelect;
