import React, { useState } from 'react';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useLocalStorage } from "usehooks-ts";
import Tooltip from "../Tooltip/Tooltip";

const CreateGroup = ({ reloadAfterChanges }) => {
    const [isUser] = useLocalStorage('isUser', '');
    const [isToken] = useLocalStorage('isToken', '');
    const [isGroupName, setGroupName] = useState('');
    const [isPermObject, setPermObject] = useState(0);
    const [isPermCategory, setPermCategory] = useState(0);
    const [isPermUser, setPermUser] = useState(0);
    const [isPermGroup, setPermGroup] = useState(0);
    const [isPermComment, setPermComment] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isResponse, setResponse] = useState({})

    const handleResetInputs = () => {
        setGroupName("");
        setPermObject(0);
        setPermCategory(0);
        setPermUser(0);
        setPermGroup(0);
        setPermComment(0);
    }

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div className="innerContentTitle">
                        <h2 className="responseMessage_success">{isResponse.message ? isResponse.message : ''}</h2>
                    </div>
                )
            }

            if (isResponse.message.length < 1) {
                return <div></div>
            } else {
                return (
                    <div className="innerContentTitle">
                        <h2 className="responseMessage_error">{isResponse ? isResponse.message : ''}</h2>
                    </div>
                )
            }
        } else {
            return (
                <div className="innerContentTitle">
                    <h2>Gruppe erstellen</h2>
                </div>
            )
        }
    }

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`}};
    // --------------------------------- //

    const handleCreateGroup = async () => {
        try {
            setIsLoading(true);

            const response = await axios.post(`/mGroups.php`, {
                name: isGroupName,
                perm_object: isPermObject,
                perm_category: isPermCategory,
                perm_user: isPermUser,
                perm_group: isPermGroup,
                perm_comment: isPermComment
            }, config);

            if (response){
                setResponse(response.data)

                setTimeout(function(){
                    if (response.data.status === 1) {
                        handleResetInputs();
                        reloadAfterChanges()
                    }
                    setResponse({})
                }, 1500)
            }

        } catch (error) {
            console.error('Fehler beim Erstellen der Gruppe:', error);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="innerContentWrapper">
            {formatResponseMessage()}

            <div className="innerEditWrapper">
                <div className="innerEntry">
                    <div className="entryLabel">Name:</div>
                    <input
                        type="text"
                        value={isGroupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </div>
            </div>


            <div className="innerEditWrapper">
                <div className="entryLabel">Admin-Berechtigungen:</div>

                <div className="innerEntry">
                    <div className="entryCheckBoxWrapper">
                        <input
                            type="checkbox"
                            checked={isPermObject === 1}
                            onChange={() => setPermObject(isPermObject === 1 ? 0 : 1)}
                        />
                        <div>Bilder</div>
                    </div>

                    <div className="entryCheckBoxWrapper">
                        <input
                            type="checkbox"
                            checked={isPermCategory === 1}
                            onChange={() => setPermCategory(isPermCategory === 1 ? 0 : 1)}
                        />
                        <div>Kategorien</div>
                    </div>

                    <div className="entryCheckBoxWrapper">
                        <Tooltip color={'rgb(209, 84, 84)'} tooltipText="Gehe vorsichtig mit dieser Berechtigung um!">
                            <input
                                className="checkboxWarning"
                                type="checkbox"
                                checked={isPermUser === 1}
                                onChange={() => setPermUser(isPermUser === 1 ? 0 : 1)}
                            />
                        </Tooltip>
                        <div>Benutzer</div>
                    </div>

                    <div className="entryCheckBoxWrapper">
                        <Tooltip color={'rgb(209, 84, 84)'} tooltipText="Gehe vorsichtig mit dieser Berechtigung um!">
                            <input
                                className="checkboxWarning"
                                type="checkbox"
                                checked={isPermGroup === 1}
                                onChange={() => setPermGroup(isPermGroup === 1 ? 0 : 1)}
                            />
                        </Tooltip>
                        <div>Gruppen</div>
                    </div>

                    <div className="entryCheckBoxWrapper">
                        <input
                            type="checkbox"
                            checked={isPermComment === 1}
                            onChange={() => setPermComment(isPermComment === 1 ? 0 : 1)}
                        />
                        <div>Kommentare</div>
                    </div>
                </div>
            </div>

            <div>
                <button className="appButtonSave" onClick={handleCreateGroup} disabled={isLoading}>
                    {isLoading ? 'Erstelle Gruppe...' : 'Gruppe erstellen'}
                </button>
            </div>
        </div>
    );
};

export default CreateGroup;