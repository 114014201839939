import React from 'react';

function EmailLink({mailTo, text}) {
    const handleClick = () => {
        window.location.href = `mailto:${mailTo}`;
    };

    return (
        <span className="emailLink" onClick={handleClick}>{text ? ` ${text} ` : ` ${mailTo} `}</span>
    );
}

export default EmailLink;