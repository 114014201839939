import React, { useState, useRef, useEffect } from "react";
import { useModal } from "./ModalProvider";
import TimestampConverter from "../Utilities/TimestampConverter";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "usehooks-ts";

const EditCategoryModal = ({ initialData, section, reloadAfterChanges }) => {
    const [isUser] = useLocalStorage('isUser', '');
    const [isToken] = useLocalStorage('isToken', '');
    const [isResponse, setResponse] = useState({});
    const [responseTimer, setResponseTimer] = useState(null)
    const [editedData, setEditedData] = useState(initialData);
    const [deleteButtonText, setDeleteButtonText] = useState("Löschen");
    const deleteButtonTimerRef = useRef(null);

    const { closeModal } = useModal();

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(['_auth']);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            const response = await axios.put(`/mCategories.php/`, editedData, config);

            if (response.data.status === 1) {
                setTimeout(() => {
                    if (section && section === 'tableBody') {
                        window.location.reload();
                    } else {
                        reloadAfterChanges()
                    }

                    closeModal();
                }, 1500);
            }

            setResponse(response.data);
            setResponseTimer(true);
        } catch (error) {
            console.error("Fehler beim Updaten der Kategorie:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axios.delete("/mCategories.php", {
                data: { id: editedData.id },
                headers: config.headers,
            });

            setResponse(response.data);
            setResponseTimer(true);

            if (response.data.status === 1) {
                setTimeout(() => {
                    if (section && section === 'tableBody') {
                        window.location.reload();
                    } else {
                        reloadAfterChanges()
                    }

                    closeModal();
                }, 1500);
            }

        } catch (error) {
            console.error("Fehler beim Löschen der Kategorie:", error);
        }
    };

    const handleDeleteButtonClick = () => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        setDeleteButtonText("Doppelklicken!");

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("Löschen");
        }, 2000); // Reset the text after 2 seconds
    };

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div className="responseMessage_success">
                        {isResponse.message ? isResponse.message : ""}
                    </div>
                );
            }

            if (isResponse.message.length < 1) {
                return <div></div>;
            } else {
                return (
                    <div className="responseMessage_error">
                        {isResponse ? isResponse.message : ""}
                    </div>
                );
            }
        }
    };

    useEffect(() => {
        if (responseTimer) {
            const timerId = setTimeout(() => {
                setResponseTimer(null);
                setResponse({});
            }, 1500);

            // Clear the timer if the component unmounts
            return () => clearTimeout(timerId);
        }
    }, [responseTimer]); // Only run the effect when responseTimer changes

    return (
        <div className="modalFormContent">
            <div className="modalTitleWrapper">
                {formatResponseMessage()}

                {responseTimer === null && (
                    <>
                        <h2 className="modalTitleLeft">Kategorie bearbeiten:</h2>
                        <div className="modalTitleRight">
                            {initialData.name} ({initialData.id})
                        </div>
                    </>
                )}
            </div>

            <div className="editModalContentWrapper">
                <div className="editModalContent">

                    <div className="editModalWrapper">
                        <div className="editModalEntry">
                            <div className="entryLabel">Name:</div>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={editedData.name || ""}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="editModalEntry">
                            <div className="entryLabel">Beschreibung:</div>
                            <textarea
                                className="appTextarea"
                                id="description"
                                name="description"
                                value={editedData.description || ""}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="editModalEntry">
                            <div className="entryLabel">Erstellt am:</div>
                            <input
                                type="text"
                                id="creation_date"
                                name="creation_date"
                                disabled
                                value={TimestampConverter({
                                    timestamp: editedData.creation_date,
                                }) || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="editModalButtons">
                <button className="appButtonSave" onClick={handleSave}>
                    Speichern
                </button>
                <button
                    className="appButtonDelete"
                    onDoubleClick={handleDelete}
                    onClick={handleDeleteButtonClick}
                >
                    {deleteButtonText}
                </button>
            </div>
        </div>
    );
};

export default EditCategoryModal;
