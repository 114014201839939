const TimestampConverter = ({ timestamp, showDateAsString = false }) => {
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false, // 24-Stunden-Format
        };

        if (showDateAsString) {
            if (
                date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
            ) {
                return `Heute - ${date.toLocaleString("de-DE", { hour: "2-digit", minute: "2-digit", hour12: false })} Uhr`;
            } else if (
                date.getDate() === yesterday.getDate() &&
                date.getMonth() === yesterday.getMonth() &&
                date.getFullYear() === yesterday.getFullYear()
            ) {
                return `Gestern - ${date.toLocaleString("de-DE", { hour: "2-digit", minute: "2-digit", hour12: false })} Uhr`;
            }
        }

        return `${date.toLocaleString("de-DE", options)} Uhr`;
    };

    return formatDate(timestamp);
};

export default TimestampConverter;