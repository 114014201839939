// TableBody.js

import React, {useRef, useState} from 'react';
import { useModal } from '../../../Element/Modal/ModalProvider';
import EditUserModal from '../../../Element/Modal/EditUserModal';
import EditCategoryModal from '../../../Element/Modal/EditCategoryModal';
import TimestampConverter from '../../../Element/Utilities/TimestampConverter';
import EditObjectModal from '../../../Element/Modal/EditObjectModal';
import GetValueFromForeignKey from "../../../Element/Utilities/GetValueFromForeignKey";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faComment,
    faImage,
    faList,
    faPeopleGroup,
    faScroll,
    faUser,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import formatName from "../../../Element/Utilities/NameFormatter";
import EditGroupModal from "../../../Element/Modal/EditGroupModal";
import Tooltip from "../../../Element/Tooltip/Tooltip";
import TooltipImage from "../../../Element/Tooltip/TooltipImage";

const TableBody = ({
   tableData,
   columns,
   type,
   itemsPerPage,
   currentPage,
   secondData,
   apiConfig,
   reloadAfterChanges
}) => {
    const { openModal } = useModal();
    const [currentData, setCurrentData] = useState([]);
    const deleteButtonTimerRef = useRef(null);
    const [deleteButtonText, setDeleteButtonText] = useState("Löschen");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const thresholdWidth = 1025;


    const handleDelete = async (data, apiFile) => {
        try {
            const response = await axios.delete(`/${apiFile}.php`, {
                data: { id: data.id },
                headers: apiConfig.headers,
            });

            if (response.data.status === 1) {
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }

        } catch (error) {
            console.error("Fehler beim Löschen:", error);
        }

        // Speichere currentPage im LocalStorage beim Klicken auf "Löschen"
        localStorage.setItem('currentPage', currentPage);
    };

    const handleDeleteButtonClick = () => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        setDeleteButtonText("Doppelklick!");

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("Löschen");
        }, 2000); // Setze den Text nach 2 Sekunden zurück
    };

    const getGroupDataForUser = (data) => {
        if (type === 'ManageUserTable' && data.group_id) {
            const filteredItem = secondData.find(group => group.id === data.group_id);

            return <div>{filteredItem ? formatName(filteredItem.name) : ''}</div>
        } else {
            return <div>{'——'}</div>
        }
    };

    const openEditModal = (dataId, data) => {
        switch (type) {
            case "ManageUserTable":
                openModal(
                    <EditUserModal
                        initialData={data}
                        secondData={secondData}
                    />
                );
                break;
            case "ManageCategoryTable":
                openModal(
                    <EditCategoryModal
                        reloadAfterChanges={reloadAfterChanges}
                        initialData={data}
                        section={'tableBody'}
                    />
                );
                break;
            case "ManageObjectTable":
                openModal(
                    <EditObjectModal
                        section={'tableBody'}
                        initialData={data}
                        secondData={secondData} // Categories in this case
                    />
                );
                break;
            case "ManageGroupTable":
                openModal(
                    <EditGroupModal
                        reloadAfterChanges={reloadAfterChanges}
                        tableData={tableData}
                        initialData={data}
                        secondData={secondData}
                    />
                );
                break;
            default:
        }
    };

    const getClassnameForTd = (accessor) => {
        const capitalizedAccessor = accessor.charAt(0).toUpperCase() + accessor.slice(1);

        return (`tableBody${capitalizedAccessor}`)
    }

    const countMatches = (users, groups, groupId) => {
        let matchesCount = 0;

        for (const user of users) {
            const userGroupId = user.group_id;

            for (const group of groups) {
                if (userGroupId === group.id && userGroupId === groupId) {
                    matchesCount++;
                }
            }
        }

        return matchesCount;
    };

    const getSpecificAccessorData = (data, accessor) => {
        switch (accessor) {
            case "actions":
                if (type === 'ManageCommentsTable') {
                    return (
                        <button
                            style={{height: '1.5rem'}}
                            className="appButtonDelete"
                            onClick={handleDeleteButtonClick}
                            onDoubleClick={() => handleDelete(data, 'mComments')}
                        >
                        {deleteButtonText}
                        </button>
                    );

                } else if (type === 'ManageObjectTable') {
                    return (
                        <div className="manageObjectButtonWrapper" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                            <button
                                style={{height: '1.5rem', maxWidth: '10rem'}}
                                className="appButtonDelete"
                                onClick={handleDeleteButtonClick}
                                onDoubleClick={() => handleDelete(data, 'mObjects')}
                            >
                                {deleteButtonText}
                            </button>
                            <button
                                style={{height: '1.5rem', maxWidth: '10rem'}}
                                className="appButtonNormal"
                                onClick={() => {
                                    openEditModal(data.id, data);
                                    // Speichere currentPage im LocalStorage beim Klicken auf "Bearbeiten"
                                    localStorage.setItem('currentPage', currentPage);
                                }}
                            >
                                Bearbeiten
                            </button>
                        </div>
                    );
                } else if (type === 'ManageCategoryTable') {
                    return (
                        <div className="manageObjectButtonWrapper" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                            <button
                                style={{height: '1.5rem', maxWidth: '10rem'}}
                                className="appButtonDelete"
                                onClick={handleDeleteButtonClick}
                                onDoubleClick={() => handleDelete(data, 'mCategories')}
                            >
                                {deleteButtonText}
                            </button>
                            <button
                                style={{height: '1.5rem', maxWidth: '10rem'}}
                                className="appButtonNormal"
                                onClick={() => {
                                    openEditModal(data.id, data);
                                    // Speichere currentPage im LocalStorage beim Klicken auf "Bearbeiten"
                                    localStorage.setItem('currentPage', currentPage);
                                }}
                            >
                                Bearbeiten
                            </button>
                        </div>
                    );
                } else {
                    return (
                        <button
                            style={{height: '1.5rem', maxWidth: '10rem'}}
                            className="appButtonNormal"
                            onClick={() => {
                                openEditModal(data.id, data);
                                // Speichere currentPage im LocalStorage beim Klicken auf "Bearbeiten"
                                localStorage.setItem('currentPage', currentPage);
                            }}
                        >
                            Bearbeiten
                        </button>
                    );
                }
            case "object_id":
                if (type === 'ManageCommentsTable') {
                    return (
                        <TooltipImage imgSrc={data.thumbnail_path} size={'6rem'}>
                            <div>
                                <div className="tableBodyPreviewImageId">{data.object_id}</div>
                            </div>
                        </TooltipImage>
                    )
                }
            case "thumbnail_path":
                return (
                    <div className="tableBodyPreviewImage">
                        {windowWidth > thresholdWidth ? (
                            <TooltipImage imgSrc={data.thumbnail_path} size={'15rem'}>
                                <img src={data.thumbnail_path} width="100%" alt={data.thumbnail_path} />
                            </TooltipImage>
                        ) : (
                            <img src={data.thumbnail_path} width="100%" alt={data.thumbnail_path}/>
                        )}
                    </div>
                );

            case "creation_date":
            case "created_at":
                return TimestampConverter({ timestamp: data[accessor] });

            case "category_id":
                return GetValueFromForeignKey( data.category_id, secondData, 'name' )

            case "group_id":
                return data && secondData && getGroupDataForUser(data)

            case "user_name":
            case "username":
                return formatName(data.username || data.user_name)

            case "firstname":
                return formatName(data.firstname)

            case "lastname":
                return formatName(data.lastname)

            case "name":
                if (type === 'ManageGroupTable') {
                    return (
                        <div className="tableBodyGroupWrapper">
                            <div className="tableBodyGroupName">{formatName(data.name)}</div>
                            <Tooltip tooltipText={'Anzahl Benutzer in dieser Gruppe'}>
                                <div className="tableBodyGroupUserCount">
                                    {`( ${countMatches(secondData, tableData, data.id)} )`}
                                </div>
                            </Tooltip>

                        </div>
                    )
                } else {
                    return formatName(data.name)
                }

            case "permissions":
                return (
                    <div className="tableBodyPermissionIcons">

                        <Tooltip tooltipText={'Bilder verwalten'}>
                            <div>
                                {data.perm_object === 1 ? (
                                    <FontAwesomeIcon className="permissionIconEnabled" icon={faImage} />
                                ) : (<FontAwesomeIcon className="permissionIconDisabled" icon={faImage} />)}
                            </div>
                        </Tooltip>

                        <Tooltip tooltipText={'Kategorien verwalten'}>
                            <div>
                                {data.perm_category === 1 ? (
                                    <FontAwesomeIcon className="permissionIconEnabled" icon={faList} />
                                ) : (<FontAwesomeIcon className="permissionIconDisabled" icon={faList} />)}
                            </div>
                        </Tooltip>

                        <Tooltip tooltipText={'Benutzer verwalten'}>
                            <div>
                                {data.perm_user === 1 ? (
                                    <FontAwesomeIcon className="permissionIconEnabled" icon={faUser} />
                                ) : (<FontAwesomeIcon className="permissionIconDisabled" icon={faUser} />)}
                            </div>
                        </Tooltip>

                        <Tooltip tooltipText={'Gruppen verwalten'}>
                            <div>
                                {data.perm_group === 1 ? (
                                    <FontAwesomeIcon className="permissionIconEnabled" icon={faPeopleGroup} />
                                ) : (<FontAwesomeIcon className="permissionIconDisabled" icon={faPeopleGroup} />)}
                            </div>
                        </Tooltip>

                        <Tooltip tooltipText={'Kommentare verwalten'}>
                            <div>
                                {data.perm_comment === 1 ? (
                                    <FontAwesomeIcon className="permissionIconEnabled" icon={faComment} />
                                ) : (<FontAwesomeIcon className="permissionIconDisabled" icon={faComment} />)}
                            </div>
                        </Tooltip>
                    </div>
                )

            case "status":
                if (data.status === 'active') {
                    return (
                        <Tooltip tooltipText={'Aktivierter Benutzer'} color={'rgb(113, 209, 113)'}>
                            <FontAwesomeIcon style={{color: '#71d171', fontSize: '1.15rem'}} icon={faCheck}/>
                        </Tooltip>
                    )
                }

                if (data.status === 'registered') {
                    return (
                        <Tooltip tooltipText={'Registrierter Benutzer'} color={'white'}>
                            <FontAwesomeIcon style={{color: 'papayawhip', fontSize: '1.15rem'}} icon={faScroll} />
                        </Tooltip>
                    )
                }

                if (data.status === 'disabled') {
                    return (
                        <Tooltip tooltipText={'Deaktivierter Benutzer'} color={'rgb(209, 84, 84)'}>
                            <FontAwesomeIcon style={{color: '#d15454', fontSize: '1.15rem'}} icon={faXmark} />
                        </Tooltip>
                    )
                }
                break;

            default:
                return data[accessor] ? data[accessor] : '——';
        }
    };

    React.useEffect(() => {
        const lastIndex = currentPage * itemsPerPage;
        const firstIndex = lastIndex - itemsPerPage;
        setCurrentData(tableData.slice(firstIndex, lastIndex));
    }, [currentPage, itemsPerPage, tableData]);

    return (
        <tbody>
        {currentData.map((data) => (
            <tr key={data.id}>
                {columns.map(({ accessor, label }) => (

                    // REMOVED DATA-LABEL FOR RESPONSIVE VIEW DESCRIPTION AND ACTIONS !!
                    <td
                        className={getClassnameForTd(accessor)}
                        data-label={
                            (accessor === 'description' && type === 'ManageObjectTable') ||
                            (accessor === 'message' && type === 'ManageCommentsTable') || accessor === 'actions' ?
                                '' :
                                label
                        }
                        key={accessor}
                    >

                    {getSpecificAccessorData(data, accessor)}
                    </td>
                ))}
            </tr>
        ))}
        </tbody>
    );
};

export default TableBody;

