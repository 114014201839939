import Table from "../Default/Table";

const columns = [
    { label: "ID", accessor: "id", sortable: true },
    { label: "Gruppe", accessor: "group_id", sortable: true },
    { label: "Vorname", accessor: "firstname", sortable: true },
    { label: "Nachname", accessor: "lastname", sortable: true },
    { label: "Benutzername", accessor: "username", sortable: true },
    { label: "Status", accessor: "status", sortable: true },
    { label: "Email", accessor: "email", sortable: true },
    { label: "Aktionen", accessor: "actions" }
];

const ManageUserTable = ({data, handleOpenModal, type, secondData} ) => {
    return (
        <>
            <Table
                caption=""
                data={data}
                type={type}
                secondData={secondData}
                handleOpenModal={handleOpenModal}
                columns={columns}
                itemsPerPage={10}
            />
        </>
    );
};

export default ManageUserTable;
