import React from 'react';
import './FooterSites.css';
import EmailLink from "../../Element/EmailLink/EmailLink";

const PrivacyPolicy = () => {

    return (
        <>
            <div className="contentWrapper">
                <div className="normalForm" style={{maxWidth: '40rem', width: '100%'}}>
                    <div className="loginFormContent">
                        <h1 className="loginTitle">
                            Datenschutzerklärung
                        </h1>

                        <div className="formContainer">
                            <div className="innerEditWrapper" style={{background: '#0000001a'}}>
                                <div>

                                    <div className="innerTextWrapper">
                                        <p style={{marginTop: '0', marginBottom: '2rem'}}>
                                            Vielen Dank für Ihren Besuch auf unserer Website. Der Schutz Ihrer Privatsphäre
                                            ist uns wichtig. Nachstehend informieren wir Sie über die Verarbeitung Ihrer
                                            personenbezogenen Daten und Ihre Rechte im Zusammenhang mit der Nutzung unserer Website.
                                        </p>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Verantwortlich für die Datenverarbeitung</h2>
                                        <div className="innerTextWrapper">
                                            <div>Marcel Widl</div>
                                            <div>E-Mail: <EmailLink mailTo={'support@seelenwesen.de'}/></div>
                                            <div className="innerTextInfo">(Bitte kontaktieren Sie uns per E-Mail, um die vollständige Adresse zu erhalten.)</div>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Arten der verarbeiteten Daten und Zwecke der Verarbeitung</h2>
                                        <div className="innerTextWrapper">
                                            <p>Wir verarbeiten personenbezogene Daten nur, soweit dies zur Bereitstellung unserer Dienste erforderlich ist oder Sie uns Ihre Einwilligung erteilt haben.</p>
                                            <p>Die von uns verarbeiteten Daten umfassen:</p>
                                            <ul>
                                                <li>Registrierung und Benutzerkonten: Name, E-Mail-Adresse.</li>
                                                <li>Interaktionen auf der Website: Kommentare und Likes.</li>
                                                <li>Automatisch erhobene Daten: IP-Adresse, Browsertyp, Betriebssystem, Zugriffszeitpunkt und besuchte Seiten, um die Sicherheit und Funktionalität der Website zu gewährleisten.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Cookies und ähnliche Technologien</h2>
                                        <div className="innerTextWrapper">
                                            <p>Wir verwenden Cookies, um die Authentifizierung und Sicherheit zu ermöglichen. Diese Cookies sind technisch notwendig und werden nach 72 Stunden automatisch gelöscht. Nach Ablauf der Frist müssen Sie sich erneut einloggen.</p>
                                            <p>Hinweis: Sie können die Speicherung von Cookies durch Ihre Browsereinstellungen kontrollieren und diese bei Bedarf deaktivieren. Dies kann jedoch die Nutzung bestimmter Funktionen der Website beeinträchtigen.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Speicherung und Löschung von Benutzerdaten</h2>
                                        <div className="innerTextWrapper">
                                            <p>Benutzer haben jederzeit die Möglichkeit, ihren Account eigenständig zu löschen. Bei der Löschung werden sämtliche personenbezogenen Daten, einschließlich aller Kommentare und Likes, vollständig und unwiderruflich entfernt.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Nutzung von Local Storage</h2>
                                        <div className="innerTextWrapper">
                                            <p>Wir verwenden den Local Storage Ihres Geräts, um nicht personenbezogene Daten (z. B. Einstellungen) zu speichern. Diese Daten werden lokal auf Ihrem Gerät gespeichert und nicht an uns übermittelt.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Externe Dienste und Drittanbieter</h2>
                                        <div className="innerTextWrapper">
                                            <p>Font Awesome Icons: Wir verwenden Icons von Font Awesome auf unserer Website. Diese Nutzung kann anonyme Statistiken durch Font Awesome beinhalten. Weitere Informationen finden Sie in der Datenschutzerklärung von Font Awesome: <a href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a>.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Galerieinhalt</h2>
                                        <div className="innerTextWrapper">
                                            <p>Alle auf dieser Website gezeigten Bilder wurden von Silvia Riedmaier selbst erstellt. Eine Verwendung oder Weitergabe dieser Bilder bedarf der vorherigen Zustimmung von Frau Riedmaier.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Ihre Rechte</h2>
                                        <div className="innerTextWrapper">
                                            <p>Sie haben gemäß der DSGVO folgende Rechte:</p>
                                            <ul>
                                                <li>Auskunftsrecht: Sie können Auskunft über die über Sie gespeicherten Daten verlangen.</li>
                                                <li>Recht auf Berichtigung: Sie haben das Recht, unrichtige Daten korrigieren zu lassen.</li>
                                                <li>Recht auf Löschung („Recht auf Vergessenwerden“): Sie können die Löschung Ihrer Daten verlangen.</li>
                                                <li>Recht auf Einschränkung der Verarbeitung: Unter bestimmten Bedingungen können Sie die Einschränkung der Verarbeitung Ihrer Daten verlangen.</li>
                                                <li>Widerspruchsrecht: Sie können der Verarbeitung Ihrer Daten widersprechen.</li>
                                                <li>Recht auf Datenübertragbarkeit: Sie können die Übermittlung Ihrer Daten an einen anderen Verantwortlichen verlangen.</li>
                                            </ul>
                                            <p>Zur Ausübung Ihrer Rechte oder bei Fragen zur Verarbeitung Ihrer Daten wenden Sie sich bitte per E-Mail an: support@seelenwesen.de.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <h2>Aktualisierung der Datenschutzerklärung</h2>
                                        <div className="innerTextWrapper">
                                            <p>Diese Datenschutzerklärung kann aktualisiert werden, um Änderungen in unseren Datenschutzpraktiken zu reflektieren. Bitte überprüfen Sie diese Seite regelmäßig auf Aktualisierungen.</p>
                                        </div>
                                    </div>

                                    <div className="entryContainer">
                                        <div className="innerTextWrapper">
                                            <p>Letzte Aktualisierung: 13.11.2024</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default PrivacyPolicy;