import React, { useState, useEffect } from 'react';
import './CookieAcceptance.css';

const CookieAcceptance = () => {
    const [accepted, setAccepted] = useState(false);
    const [showComponent, setShowComponent] = useState(false);

    useEffect(() => {
        const isAccepted = localStorage.getItem('cookieAccepted');
        if (isAccepted) {
            setAccepted(true);
        } else {
            // Delay for cookie acceptance
            const timeoutId = setTimeout(() => {
                setShowComponent(true);
            }, 3000);

            // Remove timer to eliminate memory leaks
            return () => clearTimeout(timeoutId);
        }
    }, []);

    const handleAccept = () => {
        setAccepted(true);
        setShowComponent(false);
        localStorage.setItem('cookieAccepted', 'true');
    };

    if (accepted || !showComponent) {
        // If cookies have been accepted
        return null;
    }

    return (
        <div className={`cookie-acceptance ${showComponent ? 'show' : ''}`}>
            <p className="cookie-acceptance-message">
                Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten.
            </p>
            <button className="appButtonNormal" style={{ width: '20%', minWidth: '10rem', maxWidth: '15rem' }} onClick={handleAccept}>
                Cookies akzeptieren
            </button>
        </div>
    );
};

export default CookieAcceptance;