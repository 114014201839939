import React, { useEffect, useRef, useState } from "react";

export default function withClickOutside(WrappedComponent) {
    return (props) => {
        const [open, setOpen] = useState(false);

        const ref = useRef();

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (!ref.current.contains(event.target)) {
                    setOpen(false);
                }
            };

            document.addEventListener("mousedown", handleClickOutside);

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);

        // Function to close the menu when a div inside WrappedComponent is clicked
        const handleDivClick = () => {
            setOpen(false);
        };

        // Include the handleDivClick function in the props passed to WrappedComponent
        const wrappedComponentProps = {
            ...props,
            open,
            setOpen,
            handleDivClick,
            ref,
        };

        return <WrappedComponent {...wrappedComponentProps} />;
    };
}
