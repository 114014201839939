import React, { useState, useRef, useEffect } from "react";
import { useModal } from "./ModalProvider";
import TimestampConverter from "../Utilities/TimestampConverter";
import CustomSelect from "../Custom/CustomSelect";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "usehooks-ts";
import axios from "axios";
import GetValueFromForeignKey from "../Utilities/GetValueFromForeignKey";

const EditObjectModal = ({ initialData, secondData, section }) => {
    const [isUser] = useLocalStorage("isUser", "");
    const [isToken] = useLocalStorage("isToken", "");
    const [editedData, setEditedData] = useState(initialData);
    const [deleteButtonText, setDeleteButtonText] = useState("Löschen");
    const deleteButtonTimerRef = useRef(null);
    const [isResponse, setResponse] = useState({});
    const [responseTimer, setResponseTimer] = useState(null);

    const { closeModal } = useModal();

    // -------- Cookie header ---------- //
    const [cookies] = useCookies(["_auth"]);
    const token = cookies._auth;
    const config = {
        headers: {
            Authorization: `${token}`,
            RequestUser: `${isUser}`,
            LToken: `${isToken}`,
        },
    };
    // --------------------------------- //

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (name, value) => {
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            const response = await axios.put(`/mObjects.php/`, editedData, config);

            if (response.data.status === 1) {
                setTimeout(() => {
                    if (section && section === 'tableBody') {
                        window.location.reload();
                    }
                    closeModal();
                }, 1500);
            }

            setResponse(response.data);
            setResponseTimer(true);
        } catch (error) {
            console.error("Fehler beim Updaten des Bildes:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await axios.delete("/mObjects.php", {
                data: { id: editedData.id },
                headers: config.headers,
            });

            setResponse(response.data);
            setResponseTimer(true);

            if (response.data.status === 1) {
                setTimeout(() => {
                    if (section && section === 'tableBody') {
                        window.location.reload();
                    }
                    closeModal();
                }, 1500);
            }

        } catch (error) {
            console.error("Fehler beim Löschen des Bildes:", error);
        }
    };

    const handleDeleteButtonClick = () => {
        if (deleteButtonTimerRef.current) {
            clearTimeout(deleteButtonTimerRef.current);
        }

        setDeleteButtonText("Doppelklicken!");

        deleteButtonTimerRef.current = setTimeout(() => {
            setDeleteButtonText("Löschen");
        }, 2000); // // Reset the text after 2 seconds
    };

    const formatResponseMessage = () => {
        if (isResponse && isResponse.message) {
            if (isResponse.status === 1) {
                return (
                    <div className="responseMessage_success">
                        {isResponse.message ? isResponse.message : ""}
                    </div>
                );
            }

            if (isResponse.message.length < 1) {
                return <div className="responseMessage_success"></div>;

            } else {
                return (
                    <div className="responseMessage_error">
                        {isResponse ? isResponse.message : ""}
                    </div>
                );
            }
        }
    };

    useEffect(() => {
        if (responseTimer) {
            const timerId = setTimeout(() => {
                setResponseTimer(null);
                setResponse({});
            }, 1500);

            // Clear the timer if the component unmounts
            return () => clearTimeout(timerId);
        }
    }, [responseTimer]); // Only run the effect when responseTimer changes

    return (
        <div className="modalFormContent">
            <div className="modalTitleWrapper">
                {formatResponseMessage()}

                {responseTimer === null && (
                    <>
                        <h2 className="modalTitleLeft">Bild bearbeiten:</h2>
                        <div className="modalTitleRight">
                            {initialData.name} ({initialData.id ? initialData.id : initialData.object_id})
                        </div>
                    </>
                )}

        </div>

    <div className="editModalContentWrapper">
                <div className="editModalContent">

                    <img
                        src={initialData.thumbnail_path}
                        width="100%"
                        alt={initialData.thumbnail_path}
                    />

                    <div className="editModalWrapper">
                        <div className="editModalEntry">
                            <div className="entryLabel">Name:</div>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={editedData.name || ""}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="editModalEntry">
                            <div className="entryLabel">Beschreibung:</div>
                            <textarea
                                className="appTextarea"
                                id="description"
                                name="description"
                                value={editedData.description || ""}
                                onChange={handleChange}
                            />
                        </div>

                        {secondData && (
                            <div className="editModalEntry">
                                <div className="entryLabel">Kategorie:</div>
                                <div style={{width: "100%"}}>
                                    <CustomSelect
                                        optionArray={secondData}
                                        onSelect={(selectedOption) =>
                                            handleSelectChange("category_id", selectedOption)
                                        }
                                        defaultValue={GetValueFromForeignKey(
                                            editedData.category_id,
                                            secondData,
                                            "name"
                                        )}
                                        optionKey="category_id"
                                    />
                                </div>
                            </div>
                        )}

                        <div className="editModalEntry">
                            <div className="entryLabel">Erstellt am:</div>
                            <input
                                type="text"
                                id="creation_date"
                                name="creation_date"
                                disabled
                                value={TimestampConverter({
                                    timestamp: editedData.creation_date,
                                }) || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="editModalButtons">
                    <button className="appButtonSave" onClick={handleSave}>
                        Speichern
                    </button>
                    <button
                        className="appButtonDelete"
                        onDoubleClick={handleDelete}
                        onClick={handleDeleteButtonClick}
                    >
                        {deleteButtonText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditObjectModal;
