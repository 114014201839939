// Table.js
import React, { useEffect, useState } from "react";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import { useSortableTable } from "./useSortableTable";
import Pagination from "./Pagination";

const Table = ({
    caption,
    data,
    columns,
    type,
    itemsPerPage,
    secondData,
    apiConfig,
    reloadAfterChanges
}) => {

    const [tableData, handleSorting] = useSortableTable(data, columns);
    const [currentPage, setCurrentPage] = useState(
        parseInt(localStorage.getItem("currentPage")) || 1
    );
    const [searchTerm, setSearchTerm] = useState("");
    const totalPages = Math.ceil(tableData.length / itemsPerPage);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(totalPages > 0 ? totalPages : 1); // Setze currentPage auf die letzte Seite, falls totalPages > 0, ansonsten auf Seite 1
        } else {
            localStorage.setItem("currentPage", currentPage);
            localStorage.setItem("totalPages", totalPages);
        }

        return () => {
            localStorage.removeItem("currentPage");
            localStorage.removeItem("totalPages");
        };
    }, [currentPage, totalPages]);

    const filteredData = tableData.filter((item) =>
        Object.values(item).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Suche..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />

            <table className="table">
                <caption>{caption}</caption>
                <TableHead {...{ columns, handleSorting }} />
                <TableBody
                    {...{
                        columns,
                        tableData: filteredData,
                        type,
                        itemsPerPage,
                        currentPage,
                        secondData,
                        apiConfig,
                        reloadAfterChanges
                    }}
                />
            </table>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        </>
    );
};

export default Table;