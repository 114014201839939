import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {UserContext} from "../../App";
import DropdownButton from "../../Element/DropdownButton/DropdownButton";

export default function Header({isResponsive}) {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const [isImageValid, setImageValid] = useState(true);

    const handleImageError = () => {
        setImageValid(false);
    };

    const getUppercaseUserName = () => {
        if (user) {
            const userName = user.username ? user.username : 'Loading...';
            return userName[0].toUpperCase() + userName.slice(1);
        }
    }

    function handleClick () {
        navigate('/')
        window.location.reload()
    }

    return (
        <>
            <header className="appHeader">
                <div className="navBarWrapper">
                    <div className="navBarUl">

                        <div className="navBarUlContent">
                            <div className="leftPart">
                                <Link className="navBarHome" to={'/'} onClick={handleClick}>
                                    <FontAwesomeIcon onClick={handleClick} className="rainbow" icon="fab fa-pagelines"/>
                                </Link>

                                <Link to={'../about-me'}>
                                    <button className="navBarButton-aboutMe">
                                        {'Über mich'}
                                    </button>
                                </Link>

                                <Link to={'../comments'}>
                                    <button className="navBarButton-comments">
                                        {'Kommentare'}
                                    </button>
                                </Link>

                            </div>

                            <div className="middlePart">
                            </div>

                            <div className="rightPart">
                                {user.username && user.username.length > 1 ? (
                                    <>
                                        {isResponsive === "false" && (
                                            <Link to={'/edit/profile'}>
                                                <div className="welcomeGreeting">
                                                    {`Hallo, ${getUppercaseUserName()}`}
                                                    <div>
                                                        {user.avatar_path ? (
                                                            isImageValid ? (
                                                                <img className="welcomeGreetingIcon" src={user.avatar_path} alt={'1.png'} onError={handleImageError} />
                                                            ) : (
                                                                <div className="welcomeGreetingIcon">
                                                                    {user.username.substring(0, 2).toUpperCase()}
                                                                </div>
                                                            )
                                                        ) : (
                                                            <div className="welcomeGreetingIcon">
                                                                {user.username.substring(0, 2).toUpperCase()}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        )}

                                        <DropdownButton />
                                    </>
                                ) : (
                                    <Link to={'../login'}>
                                        <button className="navBarButton-login">
                                            {'Einloggen'}
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </header>
        </>
    )
}