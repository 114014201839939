import Table from "../Default/Table";

const columns = [
    { label: "ID", accessor: "id", sortable: true, defaultSort: "desc" },
    { label: "Benutzer", accessor: "user_name", sortable: true },
    { label: "Bild-ID", accessor: "object_id", sortable: true },
    { label: "Kommentar", accessor: "message", sortable: true },
    { label: "Erstellt am", accessor: "creation_date", sortable: true },
    { label: "Aktionen", accessor: "actions" }
];

const ManageCommentTable = ({ data, handleOpenModal, type, apiConfig }) => {
    return (
        <>
            <Table
                caption=""
                data={data}
                type={type}
                handleOpenModal={handleOpenModal}
                columns={columns}
                itemsPerPage={15}
                apiConfig={apiConfig}
            />
        </>
    );
};

export default ManageCommentTable;
