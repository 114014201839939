import React from 'react';
import './Tooltip.css';

const TooltipImage = ({ children, imgSrc, size }) => {
    return (
        <div className="tooltipImage">
            {children}
            <img className="tooltipImageItem" src={imgSrc}
                style={{
                    width:`${size ? size : '100%'}`,
                    minWidth:`${size ? size : '15rem'}`,
                    minHeight:`${size ? size : '15rem'}`,
                    height:`${size ? size : 'auto'}`
                }}
                alt={imgSrc}/>
        </div>
    );
};

export default TooltipImage;