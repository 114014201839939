import React, { useEffect, useState } from "react";
import "./Navigation.css";
import axios from "axios";
import DataSorter from "../../Element/Utilities/DataSorter";
import { Link, useLocation } from "react-router-dom";
import SearchInput from "../../Element/Search/SearchInput";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Navigation({ isChanged }) {
    const [isCategories, setCategories] = useState({});
    const [isLoadingCategories, setLoadingCategories] = useState(true);
    const [isActiveCategory, setActiveCategory] = useState('0');
    const [filteredResults, setFilteredResults] = useState([]);
    const [sortKey, setSortKey] = useState('name');
    const [sortOrder, setSortOrder] = useState('desc');

    useEffect(() => {
        axios
            .get(`/userCategories.php`)
            .then((response) => {

                // Sortiere den Response nach name aufsteigend und schreibe in den state
                const sortedCategories = sortedOptionArray(response.data, 'name', 'asc');
                setCategories(sortedCategories);
                setLoadingCategories(false);
            })
            .catch((error) => {
                console.error("Fehler beim Laden der Kategorien: ", error);
                setLoadingCategories(false);
            });
    }, [isChanged]);

    // Get CurrentLocation and extract ID
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const categoryId = pathSegments.pop();

    useEffect(() => {
        // Set active category to '0' if categoryId is null, undefined, or path is / or /category/0
        const newActiveCategory = (categoryId === null || categoryId === undefined || location.pathname === '/' || location.pathname === '/category/0') ? '0' : categoryId;

        setActiveCategory(parseInt(newActiveCategory));
    }, [location.pathname]);

    const sortedOptionArray = (dataArray, sortKey, order) => {
        return DataSorter({
            dataArray,
            sortKey,
            order,
        });
    };

    const handleActiveStateClick = (itemId) => {
        setActiveCategory(itemId);
    };

    const handleSearch = (results) => {
        const sortedResults = sortedOptionArray(results, sortKey, sortOrder);
        setFilteredResults(sortedResults);
    };

    useEffect(() => {
        setFilteredResults([])
    }, [categoryId]);

    const handleSort = (key) => {
        if (key === sortKey) {
            // Wechsle zwischen 'asc' und 'desc', wenn derselbe Schlüssel erneut geklickt wird
            const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
            setSortOrder(newOrder);
        } else {
            // Setze den neuen Sortierschlüssel auf 'asc'
            setSortKey(key);
            setSortOrder('asc');
        }

        // Sortiere die Daten
        const dataToSort = filteredResults.length > 0 ? filteredResults : isCategories;
        const sortedResults = sortedOptionArray(dataToSort, key, sortOrder);
        setFilteredResults(sortedResults);
    };

    const inputStyle = {
        border: 'none',
        borderBottom: '3px solid #091828',
        textAlign: 'center',
        fontWeight: 'bold',
        background: 'rgb(55 77 100)',
        color: 'white',
        height: '2.4rem',
    };

    const isHomeCategorySelected = ((
        categoryId === null ||
        categoryId === undefined ||
        location.pathname === '/' ||
        location.pathname === '/category/0' ||
        location.pathname === '/home'
    ))

    const [isCollapsed, setCollapsed] = useState(true);

    const handleCollapseToggle = () => {
        setCollapsed(!isCollapsed);
    };

    return (
        <>
            {!isLoadingCategories && (
                <>
                    <div className="searchAndHomeWrapper">
                        <div
                            className={`navigationSearchWrapper ${isCollapsed ? 'collapsed' : ''}`}
                        >
                            {!isCollapsed && (
                                <>
                                    <SearchInput style={inputStyle} data={isCategories} onSearch={handleSearch}/>
                                    <div className="sortButtons">
                                        <button
                                            className="appButtonNormal"
                                            style={{
                                                height: '1.5rem',
                                                background: 'rgb(99 147 191)',
                                                border: 'none',
                                                borderBottom: '3px solid #091828'
                                            }}
                                            onClick={() => handleSort('name')}
                                        >
                                            Sortiere Kategorien {sortOrder === 'asc' ? '▲' : '▼'}
                                        </button>
                                    </div>
                                </>
                            )}
                            <div className="collapseButton" onClick={handleCollapseToggle}>
                                {isCollapsed ? (
                                    <span className="collapseIcon">▼🔍</span>
                                ) : (
                                    <span className="collapseIcon">▲🔍</span>
                                )}
                            </div>
                        </div>
                    </div>


                    <div key='navigationItemWrapper' className={`navigationItemWrapper`}>

                        <div className="navigationHomeItem">
                            <div
                                key={`option newest`}
                                className={isHomeCategorySelected ? 'navigationItem-active' : 'navigationItem'}
                                onClick={() => handleActiveStateClick(0)}
                            >
                                <Link key={`link ${0}`} value={0} to={`/`}>
                                    <div
                                        className={isHomeCategorySelected ? 'navigationHome-active' : 'navigationHome'}>
                                        <FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={faHome}/>
                                        {' Startseite'}
                                    </div>
                                    <div
                                        className={isHomeCategorySelected ? 'navigationImageEmpty-active' : 'navigationImageEmpty'}></div>
                                </Link>
                            </div>
                        </div>

                        {filteredResults.length > 0
                            ? (
                                filteredResults.map((item, index) => (
                                    <div
                                        key={`option ${index}`}
                                        className={isActiveCategory === item.id ? 'navigationItem-active' : 'navigationItem'}
                                        onClick={() => handleActiveStateClick(item.id)}
                                    >
                                        <Link
                                            key={`link ${index}`}
                                            value={item.id}
                                            to={`/category/${item.id}`}
                                        >
                                            <>
                                                {item.lastCreatedObject ? (
                                                    <>
                                                        <div
                                                            className={isActiveCategory === item.id ? 'navigationText-active' : 'navigationText'}>
                                                            {item.name}
                                                        </div>
                                                        <img
                                                            className={isActiveCategory === item.id ? 'navigationImage-active' : 'navigationImage'}
                                                            src={item.lastCreatedObject.thumbnail_path}
                                                            alt={`${index + 1}`}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={isActiveCategory === item.id ? 'navigationText-active' : 'navigationText'}>
                                                            {item.name}
                                                        </div>
                                                        <div
                                                            className={isActiveCategory === item.id ? 'navigationImageEmpty-active' : 'navigationImageEmpty'}></div>
                                                    </>
                                                )}
                                            </>
                                        </Link>
                                    </div>
                                ))
                            )
                            : (
                                isCategories.map((item, index) => (
                                    <div
                                        key={`option ${index}`}
                                        className={isActiveCategory === item.id ? 'navigationItem-active' : 'navigationItem'}
                                        onClick={() => handleActiveStateClick(item.id)}
                                    >
                                        <Link
                                            key={`link ${index}`}
                                            value={item.id}
                                            to={`/category/${item.id}`}
                                        >
                                            <>
                                                {item.lastCreatedObject ? (
                                                    <>
                                                        <div
                                                            className={isActiveCategory === item.id ? 'navigationText-active' : 'navigationText'}>
                                                            {item.name}
                                                        </div>
                                                        <img
                                                            className={isActiveCategory === item.id ? 'navigationImage-active' : 'navigationImage'}
                                                            src={item.lastCreatedObject.thumbnail_path}
                                                            alt={`${index + 1}`}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={isActiveCategory === item.id ? 'navigationText-active' : 'navigationText'}>
                                                            {item.name}
                                                        </div>
                                                        <div
                                                            className={isActiveCategory === item.id ? 'navigationImageEmpty-active' : 'navigationImageEmpty'}></div>
                                                    </>
                                                )}
                                            </>
                                        </Link>
                                    </div>
                                ))
                            )
                        }
                    </div>
                </>
            )}
        </>
    );
}
